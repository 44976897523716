import React, {useState, useEffect} from "react";
import "../styles/herbpharm.scss";
import Seo from "../components/seo";
import Header from "../components/herbpharm/header/header";
import LoginForm from "../components/herbpharm/loginForm/loginForm";

import {logoutAcu} from "../components/herbpharm/fetchacu";
import Herbs from "../components/herbpharm/herbs/herbs";
import Suppliers from "../components/herbpharm/suppliers/suppliers";
import Formulas from "../components/herbpharm/formulas/formulas";
import Patients from "../components/herbpharm/patients/patients";
import Users from "../components/herbpharm/users/users";
import Applicants from "../components/herbpharm/applicants/applicants";
import ErrorBox from "../components/herbpharm/errorBox/errorBox";
import Fulfillment from "../components/herbpharm/fulfillment/fulfillment";
import Revenue from "../components/herbpharm/revenue/revenue";


const defaultPage = (user) => {
    if (user.isStockManager) return 'herbs'
    if (user.isHerbalist) return 'formulas'
    if (user.isAdmin)  return 'users'
    return ''
}

const HerbPharmPage = () => {
    const [user, setUser] = useState( null)
    const [activePage, setActivePage] = useState( '' )
    const [error, setError] = useState({})

    useEffect(() => {
        if ( user === null) {
            const storedUser = JSON.parse( localStorage.getItem('user') ) || {}
            setUser(storedUser)
            setActivePage(defaultPage(storedUser))
        }
    },[user])

    const logoutHandler = () => {
        logoutAcu( localStorage.getItem('refreshToken') ).then(r => console.log(r))
        localStorage.removeItem('user')
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        setUser({})
    }

    const loginHandler = (u) => {
        setUser(u);
        setActivePage(defaultPage(u))
    }

    return (
        <>
            {( (!error.message && user !== null) ? (
                user.id
                    ?
                    <div className='app' id='top'>
                        <Seo title="Acuboston Herbal Pharmacy"/>
                        <Header user={user} activePage={activePage}
                                onLogout={logoutHandler}
                                onSetActivePage={page => setActivePage(page)} />
                        { activePage === 'herbs' && (user.isStockManager || user.isHerbalist || user.isAdmin) && (
                            <Herbs user={user} onError={e => setError(e)} /> ) }
                        { activePage === 'suppliers' && (user.isStockManager || user.isAdmin) &&  (
                            <Suppliers user={user} onError={e => setError(e)} /> ) }
                        { activePage === 'fulfillment' && (user.isStockManager || user.isAdmin) &&  (
                            <Fulfillment user={user} onError={e => setError(e)} /> ) }
                        { activePage === 'formulas' && user.isHerbalist &&  (
                            <Formulas user={user} onError={e => setError(e)} /> ) }
                        { activePage === 'patients' && user.isHerbalist &&  (
                            <Patients user={user} onError={e => setError(e)} /> ) }
                        { activePage === 'users' && user.isAdmin &&  (
                            <Users user={user} onError={e => setError(e)} onSwitch={loginHandler} /> ) }
                        { activePage === 'applicants' && user.isAdmin &&  (
                            <Applicants user={user} onError={e => setError(e)} onSwitch={loginHandler} /> ) }
                        { activePage === 'revenue' && user.isAdmin &&  (
                            <Revenue user={user} onError={e => setError(e)}  /> ) }
                    </div>
                    :
                    <div className='app' id='top'>
                        <Seo title="Acuboston Herbal Pharmacy"/>
                        <Header />
                        <LoginForm onLogIn={loginHandler} />
                    </div>
            )
            :
            <div className='app' id='top'>
                <Seo title="Acuboston Herbal Pharmacy"/>
                <Header />
            </div>
            )}
            {( error.message &&
                <ErrorBox message={error.message}
                          onCancel={ () => {
                              if (error.status && error.status === 401)
                                  logoutHandler()
                              setError(()=>({}))}} />
            )}
        </>
    )
}

export default HerbPharmPage
