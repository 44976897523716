
const caseInsensitiveSort = (selector) =>  (rowA, rowB) => {
    const a = rowA[selector].toLowerCase();
    const b = rowB[selector].toLowerCase();
    if (a > b) return 1;
    if (b > a) return -1;
    return 0;
}

export {caseInsensitiveSort}
