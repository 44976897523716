import React, {useState} from "react";
import styled from "styled-components";
import * as style from "./herbDetails.module.scss"
import {fetchAcu} from "../../fetchacu";

const Button = styled.button`
    border: outset;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    margin: 20px;
`;

const SubmitButton = styled.input.attrs({type: 'submit'})`
    border: outset;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    margin: 20px;
`;

const HerbDetails = (props) => {
    const [herbName, setHerbName] = useState(props.herb.herb_name)
    const [commonName, setCommonName] = useState(props.herb.common_name)
    const [latinName, setLatinName] = useState(props.herb.latin_name)
    const [gramCost, setGramCost] = useState(props.herb.gram_cost)
    const [lowInventory, setLowInventory] = useState(props.herb.low_inventory)
    const [notes, setNotes] = useState(props.herb.notes)
    const [isNutsAllergen, setIsNutsAllergen] = useState(Boolean(props.herb.allergens && props.herb.allergens.find( a =>  a === 'Nuts')) )
    const [isGlutenAllergen, setIsGlutenAllergen] = useState(Boolean(props.herb.allergens && props.herb.allergens.find( a =>  a === 'Gluten')) )
    const [isShellfishAllergen, setIsShellfishAllergen] = useState(Boolean(props.herb.allergens && props.herb.allergens.find( a =>  a === 'Shellfish')) )
    const [otherAllergens, setOtherAllergens]
        = useState(props.herb.allergens
        && props.herb.allergens.filter((a) => a !== 'Nuts' && a !== 'Gluten' && a !== 'Shellfish').join(', '))

    const submitHandler = async e => {
        e.preventDefault()

        const allergens = []
        if (isNutsAllergen) allergens.push('Nuts')
        if (isGlutenAllergen) allergens.push('Gluten')
        if (isShellfishAllergen) allergens.push('Shellfish')
        if (otherAllergens) allergens.push( ...otherAllergens.split(/,\s*/) )


        let herb = {
            herb_name: herbName,
            common_name: commonName,
            latin_name: latinName,
            gram_cost: gramCost,
            low_inventory: lowInventory,
            notes: notes,
            allergens: allergens,
        };
        if ( props.herb.herb_code  ) {
            let response = await fetchAcu('stock/herb/' +  props.herb.herb_code,
                {method: 'PUT', body: JSON.stringify(herb)
                })
            if (response.status === 200) {
                const okMessage = await response.text()
                console.log("Update herb: " + okMessage)
                herb.herb_code = props.herb.herb_code; props.onUpdate(herb)
            } else {
                const errorMessage = await response.text()
                console.log("Update herb error: " + errorMessage)
                props.onError({status: response.status, message: errorMessage})
            }
        } else {
            let response = await fetchAcu('stock/herb', {method: 'POST', body: JSON.stringify(herb) })
            if (response.status === 200) {
                const data = await response.json()
                console.log("Create herb: " + data)
                herb.herb_code = data.herb_code;
                herb.inventory = 0;
                herb.bottles = [];
                props.onCreate(herb)
            } else {
                const errorMessage = await response.text()
                console.log("Create herb error: " + errorMessage)
                props.onError({status: response.status, message: errorMessage})
            }
        }
        console.log('herb submitted')
    }

    return (
        <form onSubmit={submitHandler}>
        <div className={style.herbDetails}>
            <div className={style.herbDetailsHeader}>
                <h2>
                    { props.herb.herb_code ? "Edit Herb Details" : "Add a New Herb" }
                </h2>
            </div>
            <div className={style.herbDetailsContent}>
                { props.herb.herb_code && (
                    <div className={style.herbDetailsContentRow}>
                        <div>{"Herb Code:"}</div>
                        <div><input type={'text'} disabled={'disabled'} value={props.herb.herb_code} /></div>
                    </div>
                )}
                <div className={style.herbDetailsContentRow}>
                    <div>{"Traditional Name:"}</div>
                    <div><input type={'text'} defaultValue={props.herb.herb_name}
                                required={true}
                                onChange={e => setHerbName(e.target.value)} /></div>
                </div>
                <div className={style.herbDetailsContentRow}>
                    <div>{"Common Name:"}</div>
                    <div><input type={'text'} defaultValue={props.herb.common_name}
                                required={true}
                                onChange={e => setCommonName(e.target.value)} /></div>
                </div>
                <div className={style.herbDetailsContentRow}>
                    <div>{"Latin Name:"}</div>
                    <div><input type={'text'} defaultValue={props.herb.latin_name}
                                required={true}
                                onChange={e => setLatinName(e.target.value)} /></div>
                </div>
                <div className={style.herbDetailsContentRow}>
                    <div>{"Gram Cost:"}</div>
                    <div><input type={'number'} defaultValue={props.herb.gram_cost}
                                required={true} step={0.01}
                                onChange={e => setGramCost(e.target.value)} /></div>
                </div>
                <div className={style.herbDetailsContentRow}>
                    <div>{"Low Inventory Margin:"}</div>
                    <div><input type={'number'} defaultValue={props.herb.low_inventory}
                                required={true}
                                onChange={e => setLowInventory(e.target.value)} /></div>
                </div>
                <div className={style.herbDetailsContentRow}>
                    <div>{"Allergens:"}
                        <label htmlFor={'nuts'}>Nuts:</label>
                        <input id={'nuts'} type={'checkbox'}
                               defaultChecked={isNutsAllergen}
                               onClick={e => setIsNutsAllergen(e.target.checked)}
                        />,

                        <label htmlFor={'gluten'}>Gluten:</label>
                        <input id={'gluten'} type={'checkbox'}
                               defaultChecked={isGlutenAllergen}
                               onClick={e => setIsGlutenAllergen(e.target.checked)}
                        />,

                        <label htmlFor={'shellfish'}>Shellfish:</label>
                        <input id={'shellfish'} type={'checkbox'}
                               defaultChecked={isShellfishAllergen}
                               onClick={e => setIsShellfishAllergen(e.target.checked)}
                        />,

                        <label htmlFor={'other'}>other:</label>
                        <input id={'other'} type={'text'} style={{width: '110px'}}
                        defaultValue={otherAllergens}  onChange={e => setOtherAllergens(e.target.value)} />
                    </div>

                </div>
                <div className={style.herbDetailsContentRow}>
                    <div>{"Notes:"}</div>
                    <div><textarea onChange={ e => setNotes(e.target.value) }>{notes}</textarea></div>
                </div>
            </div>

            <div className={style.herbDetailsFooter}>
                <SubmitButton
                value={ props.herb.herb_code ? "Save" : "Submit" }
                />
                <Button onClick={() => { props.onCancel(); return false}}>Cancel</Button>
            </div>
        </div>
        </form>
    );
};

export default HerbDetails;