// extracted by mini-css-extract-plugin
export var addComponentContainer = "prescriptionDetails-module--add-component-container--APGIH";
export var addPartsButton = "prescriptionDetails-module--add-parts-button--J80DO";
export var addPartsInput = "prescriptionDetails-module--add-parts-input--CMeQ1";
export var allergens = "prescriptionDetails-module--allergens--3w2w9";
export var buttonRemoveComponent = "prescriptionDetails-module--button-remove-component--h2a7s";
export var herbSelect = "prescriptionDetails-module--herb-select--QZPKz";
export var paddingLeft = "prescriptionDetails-module--padding-left--9lBus";
export var prescriptionDetailsColumns = "prescriptionDetails-module--prescription-details-columns--KCoj1";
export var prescriptionDetailsContainer = "prescriptionDetails-module--prescription-details-container--K-ndw";
export var prescriptionDetailsContent = "prescriptionDetails-module--prescription-details-content--2iz-O";
export var prescriptionDetailsContentLeft = "prescriptionDetails-module--prescription-details-content-left--PnTRf";
export var prescriptionDetailsContentRight = "prescriptionDetails-module--prescription-details-content-right--u8s11";
export var prescriptionDetailsContentRow = "prescriptionDetails-module--prescription-details-content-row--HPY5D";
export var prescriptionDetailsContentRowLeft = "prescriptionDetails-module--prescription-details-content-row-left--0mSdU";
export var prescriptionDetailsFooter = "prescriptionDetails-module--prescription-details-footer--N8AXL";
export var prescriptionDetailsHeader = "prescriptionDetails-module--prescription-details-header--I97I6";
export var prescriptionDetailsShippingRow = "prescriptionDetails-module--prescription-details-shipping-row--pKpsz";
export var selectStatus = "prescriptionDetails-module--select-status--VWqNe";