import React, {useState} from "react";
import {fetchAcu} from "../../fetchacu";
import * as style from "./editFormula.module.scss";

const EditFormula = (props) => {

    const [name, setName] = useState(props.formula.formula_name)
    const [notes, setNotes] = useState(props.formula.notes)
    const [classic, setClassic] = useState(props.formula.is_classic)

    const handleSubmit = async (e) => {
        e.preventDefault()
        let formula = {
            formula_name: name,
            is_classic: classic,
            notes: notes,
        }
        if (props.formula.formula_id) { // edit
            let response = await fetchAcu('office/formula/' + props.formula.formula_id, {method: 'PUT', body: JSON.stringify(formula)})
            if (response.status === 200) {
                const okMessage = await response.text()
                console.log("Update formula: " + okMessage)
                formula.formula_id = props.formula.formula_id;
                formula.components = props.formula.components;
                formula.allergens = props.formula.allergens;
                props.onFormulaEdit(formula)
            } else {
                const errorMessage = await response.text()
                console.log("Update formula error: " + errorMessage)
                props.onError({status: response.status, message: errorMessage})
            }
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className={style.editFormulaPad}>
                <div className={style.editFormula}>
                    <div className={style.editFormulaTitleBar}>{'Edit Formula'}</div>
                    <div className={style.editFormulaContent}>
                        <div className={style.editFormulaRow}>
                            <label htmlFor={'name'}>Formula name:</label>
                            <input type={'text'} id={'name'} defaultValue={props.formula.formula_name}
                                   onChange={(e)=>setName(e.target.value)}/>
                        </div>
                        { props.withClassic && (
                            <div className={style.editFormulaRow}>
                                <div>{"Classic:"}</div>
                                <div><input type={'checkbox'} checked={classic}
                                            onChange={e => setClassic(!classic)} /></div>
                            </div>
                        )}
                        <div className={style.editFormulaRow}>
                            <label htmlFor={'notes'}>Notes:</label>
                            <textarea id={'notes'}
                                      defaultValue={props.formula.notes}
                                      onChange={(e)=>setNotes(e.target.value)}></textarea>
                        </div>
                        <div className={style.editFormulaButtonsRow}>
                            <input className={style.editFormulaButton} type={'submit'}
                                   value={ 'Save' } />
                            <button className={style.editFormulaButton} onClick={()=>{props.onCancel();return false}}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default EditFormula