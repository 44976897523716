import React, {useState} from "react";
import * as style from "./patientDetails.module.scss"
import {fetchAcu} from "../../fetchacu";

const PatientDetails = (props) => {
    const [patientName, setPatientName] = useState(props.patient.patient_name)
    const [phoneNumber, setPhoneNumber] = useState(props.patient.phone_number)
    const [email, setEmail] = useState(props.patient.email)
    const [notes, setNotes] = useState(props.patient.notes)
    const handleSubmit = async (e) => {
        e.preventDefault()
        let patient = {
            patient_name: patientName,
            notes: notes,
            phone_number: phoneNumber,
            email: email,
        }
        if (props.patient.patient_id) { // edit
            let response = await fetchAcu('office/patient/' + props.patient.patient_id, {method: 'PUT', body: JSON.stringify(patient)})
            if (response.status === 200) {
                const okMessage = await response.text()
                console.log("Update patient: " + okMessage)
                patient.patient_id = props.patient.patient_id;
                props.onPatientEdit(patient)
            } else {
                const errorMessage = await response.text()
                console.log("Update patient error: " + errorMessage)
                props.onError({status: response.status, message: errorMessage})
            }
        } else { // create
            let response = await fetchAcu('office/patient', {method: 'POST', body: JSON.stringify(patient) })
            if (response.status === 200) {
                const data = await response.json()
                console.log("Create patient: " + data)
                patient.patient_id = data.patient_id;
                props.onPatientAdd(patient)
            } else {
                const errorMessage = await response.text()
                console.log("Create patient error: " + errorMessage)
                props.onError({status: response.status, message: errorMessage})
            }
        }
    }
    return (
        <form onSubmit={handleSubmit}>
        <div className={style.patientDetailsPad}>
            <div className={style.patientDetails}>
                <div className={style.patientDetailsTitleBar}>{
                    props.patient.patient_id
                        ? 'Edit patient'
                        :'New Patient'
                }</div>
                <div className={style.patientDetailsContent}>
                    <div className={style.patientDetailsRow}>
                        <label htmlFor={'patientName'}>Name:</label>
                        <input type={'text'} id={'patientName'} defaultValue={props.patient.patient_name}
                               onChange={(e)=>setPatientName(e.target.value)}/>
                    </div>
                    <div className={style.patientDetailsRow}>
                        <label htmlFor={'phoneNumber'}>Phone:</label>
                        <input type={'phone'} id={'phoneNumber'} defaultValue={props.patient.phone_number}
                               onChange={(e)=>setPhoneNumber(e.target.value)}/>
                    </div>
                    <div className={style.patientDetailsRow}>
                        <label htmlFor={'email'}>Email:</label>
                        <input type={'email'} id={'email'} defaultValue={props.patient.email}
                               onChange={(e)=>setEmail(e.target.value)}/>
                    </div>
                    <div className={style.patientDetailsRow}>
                        <label htmlFor={'notes'}>Notes:</label>
                        <textarea id={'notes'}
                                  defaultValue={props.patient.notes}
                                  onChange={(e)=>setNotes(e.target.value)}></textarea>
                    </div>
                    <div className={style.patientDetailsButtonsRow}>
                        <input className={style.patientDetailsButton} type={'submit'}
                               value={ props.patient.patient_id ? "Save" : "Submit" } />
                        <button className={style.patientDetailsButton} onClick={()=>{props.onCancel();return false}}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        </form>
    )
}

export default PatientDetails
