import React, {useState, useEffect, useMemo} from "react";
import Sprintf from "sprintf-js";
import DataTable from 'react-data-table-component'
import * as style from "./suppliers.module.scss";
import {fetchAcu} from "../fetchacu";
import styled from "styled-components";
import SupplierDetails from "./supplierDetails/supplierDetails"
import DeleteDialog from "../deleteDialog/deleteDialog";

const caseInsensitiveSort = (selector) =>  (rowA, rowB) => {
    const a = rowA[selector].toLowerCase();
    const b = rowB[selector].toLowerCase();
    if (a > b) return 1;
    if (b > a) return -1;
    return 0;
}

const columns = [
    {
        name: 'ID',
        selector: row => row.supplier_id,
        format: row => {return Sprintf.sprintf('%03d', row.supplier_id)},
        //sortable: true,
        width: '80px',
    },
    {
        name: 'Name',
        selector: row => row.supplier_name,
        sortable: true,
        sortFunction: caseInsensitiveSort('supplier_name'),
        style: {fontSize: '16px'}
    },
    {
        name: 'Notes',
        selector: row => row.notes,
        grow: 4,
    },
    {
        name: '',
        cell: (row) => (<>
            <button onClick={row.onClickEdit} className={style.buttonEditSupplier}>&#x270D;</button>
            <button onClick={row.onClickDelete} className={style.buttonRemoveSupplier}>x</button>
        </>),
        width: '110px',
    },
]

const AddSupplierButton = styled.button`
    border: outset;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
`;

// const ErrorBox = (props) => {
//     const onKeyDown = (e) => {
//         if (e.key === "Escape")
//             props.onCancel()
//     }
//     return (
//     <div role={"button"} tabIndex={0} className={style.errorPad} onKeyDown={onKeyDown} onClick={props.onCancel}>
//         <div role={"button"} tabIndex={0} className={style.errorBox} onKeyDown={onKeyDown}>
//             <div className={style.errorTitleBar}>Error</div>
//             <p>{props.message}</p>
//         </div>
//     </div>
// )}

const Suppliers = (props) => {
    const [rows, setRows] = useState([]);
    const [pending, setPending] = useState(true);
    const [showSupplierDetails, setShowSupplierDetails] = React.useState({})
    const [supplierToDel, setSupplierToDel] = useState({});

    useEffect( () => {
        fetchAcu('stock/suppliers')
            .then(
                response => {
                    if ( response.status === 200 )
                        response.json().then(
                            data => {
                                setRows( () => (data.map( item => {
                                    item.onClickDelete = () => setSupplierToDel(item);
                                    item.onClickEdit = () => setShowSupplierDetails(item);
                                    return item
                                })))
                                setPending(() => false)
                            }
                        )
                    else {
                        response.text().then(
                            errorMessage => {
                                console.log(errorMessage)
                                props.onError({status: response.status, message: errorMessage})
                            }
                        )
                    }
                }
            ).catch(err => {
                console.log(err)
                props.onError({status: 1, message: err})
        })
    }, [props]);

    const handleOnCreateSupplier = (supplier) => {
        console.log("CREATE:")
        console.log(supplier)
        supplier.onClickDelete = () => setSupplierToDel(supplier);
        supplier.onClickEdit = () => setShowSupplierDetails(supplier);
        setRows( () => rows.concat(supplier) )
        setShowSupplierDetails(() => ({}));
    }
    const handleOnUpdateSupplier = (supplier) => {
        console.log("UPDATE:")
        console.log(supplier)
        const oldSup = (rows.filter( item => item.supplier_id === supplier.supplier_id ))[0]
        console.log(oldSup)
        const newRows = rows.filter( item => item.supplier_id !== supplier.supplier_id )
        const newSup = {...oldSup, ...supplier};
        console.log(newSup)
        setRows(() => newRows.concat(newSup) )
        setShowSupplierDetails(() => ({}));
    }

    const deleteSupplier = async (supplierId) => {
        let response = await fetchAcu('stock/supplier/' + supplierId, {method: 'DELETE'});
        if (response.status !== 200) {
            let errorMessage = await response.text()
            props.onError({status: response.status, message: errorMessage})
        } else {
            let okMessage = await response.text();
            console.log("Delete: " + okMessage)
            setRows(rows.filter(row => (row.supplier_id !== supplierId)))
        }
    }

    const subHeaderComponentMemo = useMemo(() => {
            return (
                <div className={style.suppliersTableHeader}>
                    <AddSupplierButton onClick={()=>setShowSupplierDetails({new: 1})}>Add Supplier</AddSupplierButton>
                </div>
            );
        },
        []);


    return (
        <>
            {( ( showSupplierDetails.new || showSupplierDetails.supplier_id) ?
            <SupplierDetails supplier={showSupplierDetails}
                         onCancel={()=>setShowSupplierDetails({})}
                         onCreate={handleOnCreateSupplier}
                         onUpdate={handleOnUpdateSupplier}
                             onError={props.onError}
            />
            :
            <div className={style.suppliers}>
                {( supplierToDel.supplier_id &&
                    <DeleteDialog
                        onCloseModal={() => setSupplierToDel({})}
                        onClickDelete={() => {deleteSupplier(supplierToDel.supplier_id); setSupplierToDel({})} }
                        title={(<>{'You’re about to delete supplier '}<em>{supplierToDel.supplier_name}</em></>)}
                    />
                )}
            <DataTable
                columns={columns}
                keyField={"ID"}
                data={rows}
                progressPending={pending}
                persistTableHead
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                highlightOnHover={true}
                dense={true}
                defaultSortFieldId={2}
            />
            </div>
            )}
        </>)
};

export default Suppliers;