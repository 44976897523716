import React, {useState} from "react";
import styled from "styled-components";
import * as style from "./supplierDetails.module.scss"
import {fetchAcu} from "../../fetchacu";

const Button = styled.button`
    border: outset;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    margin: 20px;
`;

const SubmitButton = styled.input.attrs({type: 'submit'})`
    border: outset;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    margin: 20px;
`;

const SupplierDetails = (props) => {
    const [supplierName, setSupplierName] = useState(props.supplier.supplier_name)
    const [notes, setNotes] = useState(props.supplier.notes)

    const submitHandler = async e => {
        e.preventDefault()

        let supplier = {
            supplier_name: supplierName,
            notes: notes,
        };
        if ( props.supplier.supplier_id  ) {
            console.log("try to fetch")
            let response = await fetchAcu('stock/supplier/' +  props.supplier.supplier_id,
                {method: 'PUT', body: JSON.stringify(supplier)})
            if (response.status !== 200) {
                let errorMessage = await response.text()
                console.log(errorMessage)
                props.onError({status: response.status, message: errorMessage})
            } else {
                let okMessage = await response.text()
                console.log(okMessage)
                supplier.supplier_id = props.supplier.supplier_id; props.onUpdate(supplier)
            }
        } else {
            let response = await fetchAcu('stock/supplier', {method: 'POST', body: JSON.stringify(supplier)})
            if (response.status !== 200) {
                let errorMessage = await response.text()
                console.log(errorMessage)
                props.onError({status: response.status, message: errorMessage})
            } else {
                let data = await response.json()
                console.log(data)
                supplier.supplier_id = data.supplier_id;
                props.onCreate(supplier)
            }
        }
        console.log('supplier submitted')
    }

    return (
        <form onSubmit={submitHandler}>
            <div className={style.supplierDetails}>
                <div className={style.supplierDetailsHeader}>
                    <h2>
                        { props.supplier.supplier_id ? "Edit Supplier" : "Add a New Supplier" }
                    </h2>
                </div>
                <div className={style.supplierDetailsContent}>
                    { props.supplier.supplier_id && (
                        <div className={style.supplierDetailsContentRow}>
                            <div>{"Supplier ID:"}</div>
                            <div><input type={'text'} disabled={'disabled'} value={props.supplier.supplier_id} /></div>
                        </div>
                    )}
                    <div className={style.supplierDetailsContentRow}>
                        <div>{"Supplier Name:"}</div>
                        <div><input type={'text'} defaultValue={props.supplier.supplier_name}
                                    required={true}
                                    onChange={e => setSupplierName(e.target.value)} /></div>
                    </div>
                    <div className={style.supplierDetailsContentRow}>
                        <div>{"Notes:"}</div>
                        <div><textarea onChange={ e => setNotes(e.target.value) }>{notes}</textarea></div>
                    </div>
                </div>

                <div className={style.supplierDetailsFooter}>
                    <SubmitButton
                        value={ props.supplier.supplier_id ? "Save" : "Submit" }
                    />
                    <Button onClick={props.onCancel}>Cancel</Button>
                </div>
            </div>
        </form>
    );
};

export default SupplierDetails;