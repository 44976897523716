import React, {useEffect, useMemo, useState} from "react";
import * as style from "./formulaDetails.module.scss"
import Sprintf from "sprintf-js";
import {caseInsensitiveSort} from "../data-table-utils";
import DataTable from "react-data-table-component";
import {fetchAcu} from "../fetchacu";


const columns = [
    {
        id: 'code',
        name: 'Code',
        selector: row => row.herb_code,
        format: row => {return Sprintf.sprintf('%03d', row.herb_code)},
        width: '60px',
    },
    {
        id: 'herbName',
        name: 'Herb Name',
        selector: row => row.herb_name,
        sortable: true,
        sortFunction: caseInsensitiveSort('herb_name'),
        cell: row => { let ar =  [row.herb_name];
            if (row.common_name) {
                ar.push('(' + row.common_name+ ')')
            }
            return ar.join(' ');
        },
    },
    {
        name: 'Parts',
        selector: row => row.parts,
        format: row => ( row.parts === "0.5" ? '½' : (row.parts.match(/\.0$/) ? parseInt(row.parts) : row.parts )),
        width: '80px',
    }
]



const FormulaDetails = (props) => {
    const [formula, setFormula] = useState({formula_id: props.formulaId, components: []})
    const [bottles, setBottles] = useState({})
    const [packSubmitted, setPackSubmitted] = useState(false)
    const onError = props.onError

    const formulaComponents = formula.components.reduce((acc, r) => {
        r.grams = Math.round( 100.0 * r.parts * parseFloat(props.multiplicator) ) / 100;
        acc[r.component_id] = r
        return acc
    }, {})

    const canPack = !packSubmitted && Object.keys(bottles).reduce( (r, k) => (r && bottles[k].selected_bottle_id), true)

    const onFormulaLoad = (loadedFormula) => {
        if (props.showPack) {
            fetchAcu('stock/formulabottles/' + formula.formula_id + '?multiplicator=' + props.multiplicator)
                .then(
                    response => {
                        if (response.status === 200)
                            response.json().then(data => {
                                setBottles(data)
                                setFormula(loadedFormula)
                            })
                        else
                            response.text().then(err => {
                                console.log(err);
                                onError({status: response.status, message: err})
                            })
                    }
                ).catch(err => {
                console.log(err);
                onError({status: 1, message: err})
            })
        } else {
            setFormula(loadedFormula)
        }
    }


    useEffect( () => {
        if (formula.formula_id > 0) {
            fetchAcu('office/formula/' + formula.formula_id)
                .then(
                    response => {
                        if (response.status === 200)
                            response.json().then(data => onFormulaLoad(data))
                        else
                            response.text().then(err => {
                                console.log(err);
                                onError({status: response.status, message: err})
                            })
                    }
                ).catch(err => {
                console.log(err);
                onError({status: 1, message: err})
            })
        }
    }, [formula.formula_id])

    const preFormulaColumns = !props.prescriptionId ? columns : columns.concat(
        {
            id: "grams",
            name: "Grams",
            selector: row => row.grams,
            width: '80px'
        }
    )

    const formulaColumns = !props.showPack ? preFormulaColumns : columns.concat(
        {
            id: "bottles",
            name: "Source Bottle",
            cell: row => (<div className={style.radioCell}>
                    {  bottles[row.component_id] && bottles[row.component_id].bottles.map( b => {
                        return (<div>
                            <input type={"radio"}
                                   defaultChecked={b.bottle_id === bottles[row.component_id].selected_bottle_id }
                                   disabled={b.disabled}
                                   onClick={
                                        e => {
                                            const newBottles = JSON.parse(JSON.stringify(bottles));
                                            newBottles[row.component_id].selected_bottle_id = b.bottle_id;
                                            setBottles(newBottles)
                                        }
                                   }
                                   name={row.herb_code}/><label htmlFor={row.herb_code}>{b.current_weight}&nbsp;{b.lot}&nbsp;{b.supplier_name}</label>
                        </div>)
                    }
                        )
                    }
                </div>
            )
        }
    )

    const onPrescriptionPack = async (e) => {
        e.preventDefault();
        setPackSubmitted(true)
        console.log("pack pushed");
        const bottlesData = Object.keys(bottles).map(componentId => ({
            component_id: componentId,
            bottle_id: bottles[componentId].selected_bottle_id,
            grams: formulaComponents[componentId].grams,
        }))
        let response = await fetchAcu('stock/pack/' + props.prescriptionId,
            {method: 'POST', body: JSON.stringify(bottlesData)})
        if (response.status === 200) {
            const okMessage = await response.text()
            console.log("Pack: " + okMessage)
            props.onPrescriptionPack(props.prescriptionId)
        } else {
            const errorMessage = await response.text()
            setPackSubmitted(false)
            console.log("Pack error: " + errorMessage)
            props.onError({status: response.status, message: errorMessage})
        }
    }

    const printPrescription = () => {
        const uniqueIframeId = 'printIframe'
        let pri
        if (document.getElementById(uniqueIframeId)) {
            pri = document.getElementById(uniqueIframeId).contentWindow
        } else {
            const iframe = document.createElement('iframe')
            iframe.setAttribute('title', uniqueIframeId)
            iframe.setAttribute('id', uniqueIframeId)
            iframe.setAttribute('style', 'height: 0px; width: 0px; position: absolute;')
            document.body.appendChild(iframe)
            pri = iframe.contentWindow
        }
        pri.document.open()
/*
        pri.document.write('<html><head></head><body style="width:1.7in;font-family: sans-serif;font-size: 0.11in;">')
        pri.document.write('Patient Name: <span style="font-size: 0.14in">' + props.patientName + '</span><br/>')
        pri.document.write('Formula Code: ' + formula.formula_name + '<br/>')
        pri.document.write('Grams Prescribed: ' + props.totalGrams + '<br/>')
        pri.document.write( props.date + '<br/><br/>')

        if ( formula.allergens ) {
            pri.document.write( 'Allergens: ' + formula.allergens + '<br/><br/>')
        }

        formula.components.forEach(r => {
            pri.document.write('<div style="display:flex;justify-content: space-between;font-size: 0.11in;"><div>'+r.herb_name
                +'</div><div>'+(Math.round(100.0 * parseFloat(r.parts) * parseFloat(props.multiplicator))/100)+'g</div></div>')
        })
        pri.document.write( '<br/>Prescription notes:<br/>')
        pri.document.write( '<div style="font-size: 0.14in">'+props.notes+'</div>')
        pri.document.write('</body></html>')
 */
        pri.document.write('<html><head>')
        pri.document.write('<style>.label {\n' +
            '            overflow: hidden;\n' +
            '            position: relative;\n' +
            '            width: 80mm;\n' +
            '            display: flex;\n' +
            '            padding: 0mm 4mm;\n' +
            '            flex-direction: column;\n' +
            '        }\n' +
            '        .headimg {\n' +
            '            position: relative;\n' +
            '            max-width: 100%;\n' +
            '            height: auto;\n' +
            '            margin-bottom: 4mm;\n' +
            '        }</style>')
        pri.document.write('</head><body style="width:1.7in;font-family: sans-serif;font-size: 0.11in;">')
        pri.document.write('<div class="label">')
        pri.document.write('<img class="headimg" src="/api/v1/stock/prescriptionlogo/' + props.prescriptionId + '" />')
        pri.document.write('<div class="prescr">')
        pri.document.write('Patient Name: <span style="font-size: 0.14in">' + props.patientName + '</span><br/>')
        pri.document.write('Formula Code: ' + formula.formula_name + '<br/>')
        pri.document.write('Grams Prescribed: ' + props.totalGrams + '<br/>')
        pri.document.write( props.date + '<br/><br/>')

        if ( formula.allergens ) {
            pri.document.write( 'Allergens: ' + formula.allergens + '<br/><br/>')
        }

        formula.components.forEach(r => {
            pri.document.write('<div style="display:flex;justify-content: space-between;font-size: 0.11in;"><div>'+r.herb_name
                +'</div><div>'+(Math.round(100.0 * parseFloat(r.parts) * parseFloat(props.multiplicator))/100)+'g</div></div>')
        })
        pri.document.write( '<br/>Prescription notes:<br/>')
        pri.document.write( '<div style="font-size: 0.14in">'+props.notes+'</div>')
        pri.document.write('</div></div>')
        pri.document.write('</body></html>')
        pri.document.close()
        pri.focus()
        setTimeout( function(){ pri.print() }, 1000 )
    }

    const subHeaderComponentMemoDetails = useMemo(() => {
            return (
                <div className={style.formulaDetailsTableHeader}>
                    {( props.showPack &&
                        <button className={style.packButton}
                                disabled={!canPack}
                                onClick={onPrescriptionPack}>Pack</button>
                    )}
                    {( props.showPrint &&
                        <button className={style.printButton} onClick={(e)=>{
                            e.preventDefault();
                            printPrescription('pre' + props.prescription_id, 'printframe');
                            console.log('data')
                        }}>Print</button>
                    )}

                    <h3 className={style.formulaDetailsHeader} >
                        Formula {formula.formula_name}
                        {( formula.is_classic &&
                            <sup>classic</sup>
                        )}
                    </h3>
                    {(formula.allergens &&
                        <div className={style.formulaDetailsNotes}><span className={style.allergens}>Allergens: {formula.allergens}</span></div>
                    )}
                    {(formula.notes &&
                        <div className={style.formulaDetailsNotes}>Notes: {formula.notes}</div>
                    )}
                </div>
            );
        },
        [formula, props.showPack, props.showPrint, canPack, onPrescriptionPack, printPrescription]);



    return (
        <div>
            <form>
            <DataTable
                columns={formulaColumns}
                keyField={'code'}
                data={formula.components}
                persistTableHead
                subHeader
                subHeaderComponent={subHeaderComponentMemoDetails}
                highlightOnHover={true}
                dense={true}
                defaultSortFieldId={'herbName'}
            />
            </form>
        </div>
    )
}

export default FormulaDetails
