import React from "react";
import * as style from "./errorBox.module.scss";

const ErrorBox = (props) => {

    const onKeyDown = event => event.key === "Escape" && props.onCancel()

    return (
            <div role="button" tabIndex={0}  className={style.errorPad} onKeyDown={onKeyDown} onClick={props.onCancel}>
                <div className={style.errorBox} role="button" tabIndex={0}  onKeyDown={onKeyDown}>
                    <div className={style.errorTitleBar}>Error</div>
                    <p>{props.message.toString()}</p>
                </div>
            </div>
    )

}

export default ErrorBox