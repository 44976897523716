import React, { useState } from "react"
import * as style from "./loginForm.module.scss"
import {loginAcu} from "../fetchacu"

const LoginForm = (props) => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState('');

    const handleError = (err) => {
        setErrorMessage(err)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setErrorMessage('')
        loginAcu(email, password, props.onLogIn, handleError);
    }


    const renderErrorMessage = () =>  <div className={style.loginForm__error}>{ errorMessage }</div>

    return (
        <div className={style.loginForm__container}>
            <div className={style.loginForm__title}>Login to Acuboston Herbal Pharmacy</div>
            <form onSubmit={handleSubmit} className={style.loginForm__form}>
                <div className={style.loginForm__inputContainer}>
                    <label htmlFor={"email"}>Email </label>
                    <input type="email" name="email" id="email"
                    onChange={ (e) => { setEmail(e.target.value)} }
                           className={style.loginForm__input} required />
                </div>
                <div className={style.loginForm__inputContainer}>
                    <label htmlFor={"password"}>Password </label>
                    <input type="password" name="password" id="password"
                           onChange={ (e) => { setPassword(e.target.value)} }
                           className={style.loginForm__input} required />
                </div>
                <div className={style.loginForm__buttonContainer}>
                    <input className={style.loginForm__submit} type="submit" name="submit" />
                </div>
                { renderErrorMessage() }
            </form>
        </div>
    )
}

export default LoginForm