// extracted by mini-css-extract-plugin
export var applicantContainer = "applicantDetails-module--applicant-container--YU5s4";
export var applicantDetails = "applicantDetails-module--applicant-details--GvWFO";
export var applicantDetailsRow = "applicantDetails-module--applicant-details-row--U8wlB";
export var applicantHeader = "applicantDetails-module--applicant-header--1ocei";
export var applicantPage = "applicantDetails-module--applicant-page--tO5g7";
export var showClassic = "applicantDetails-module--show-classic--rK9JK";
export var userDetails = "applicantDetails-module--user-details--dQfvJ";
export var userDetailsContent = "applicantDetails-module--user-details-content--sVySf";
export var userDetailsContentRow = "applicantDetails-module--user-details-content-row--MyCFh";
export var userDetailsFooter = "applicantDetails-module--user-details-footer--DHXNP";
export var userDetailsHeader = "applicantDetails-module--user-details-header--1+Ulb";