// extracted by mini-css-extract-plugin
export var allergens = "formulas-module--allergens--nfTkd";
export var bottomPad = "formulas-module--bottom-pad--yzZTo";
export var buttonEditFormula = "formulas-module--button-edit-formula--IV1yn";
export var buttonRemoveFormula = "formulas-module--button-remove-formula--loUnC";
export var filterComponent = "formulas-module--filter-component--bnPwJ";
export var formulaDetails = "formulas-module--formula-details--iXzZJ";
export var formulaDetailsHeader = "formulas-module--formula-details-header--m4USh";
export var formulaDetailsNotes = "formulas-module--formula-details-notes--tiUKh";
export var formulaDetailsTableHeader = "formulas-module--formula-details-table-header--Yxlek";
export var formulas = "formulas-module--formulas--JhPck";
export var formulasPage = "formulas-module--formulas-page--5cpBu";
export var formulasTableHeader = "formulas-module--formulas-table-header--lFx1j";
export var notesCell = "formulas-module--notesCell--i-ePz";
export var showClassic = "formulas-module--show-classic--JtIaN";