import React, {useEffect, useState} from "react"
import Select from "react-select";
import * as style from "./patients.module.scss";
import {fetchAcu} from "../fetchacu";
import Prescriptions from "./prescriptions/prescriptions";
import styled from "styled-components";
import PatientDetails from "./patientDetails/patientDetails";
import {caseInsensitiveSort} from "../data-table-utils";
import DeleteDialog from "../deleteDialog/deleteDialog";

const NewPrescriptionButton = styled.button`
    border: outset;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    margin-left: 40px;
`;


const Patients = (props) => {
    const [patients, setPatients] = useState([])
    const [pending, setPending] = useState(false)
    const [selectedPatient, setSelectedPatient] = useState({})
    const [patientDetails, setPatientDetails] = useState({})
    const [patientToDel, setPatientToDel] = useState({})

    const options = patients.map(e => ({label: e.patient_name, key: e.patient_name, value: e})) || [];

    useEffect(() => {
        fetchAcu('office/patients')
            .then(
                response => {
                    if ( response.status === 200 )
                        response.json().then(data => {setPatients(data);setPending(false)})
                    else {
                        response.text().then(
                            errorMessage => {
                                console.log(errorMessage)
                                props.onError({status: response.status, message: errorMessage})
                            }
                        )
                    }
                }
            )
            .catch(err => {
                console.log(err)
                props.onError({status: -1, message: err})
            })
    }, [props])

    const handlePatientAdd = (patient) => {
        setPatients(patients.concat(patient).sort(caseInsensitiveSort('patient_name')))
        setSelectedPatient(patient)
        setPatientDetails({})
    }
    const handlePatientEdit = (patient) => {
        setPatients(patients.filter(p=>p.patient_id !== patient.patient_id).concat(patient).sort(caseInsensitiveSort('patient_name')))
        setSelectedPatient(patient)
        setPatientDetails({})
    }

    const deletePatient = async (patientId) => {
        let response = await fetchAcu('office/patient/' + patientId, {method: 'DELETE'})
        if ( response.status === 200 ) {
            const okMessage = await response.text()
            setPatients( patients.filter( row => (row.patient_id !== patientId) ))
            setSelectedPatient({})
            console.log("delete patient: " + okMessage)
        } else {
            const errorMessage = await response.text()
            console.log("delete patient error: " + errorMessage)
            props.onError({status: response.status, message: errorMessage})
        }
    }

    return (
        <>
        <div className={style.patientsContainer}>
            {( patientToDel.patient_id &&
                <DeleteDialog
                    key={patientToDel.patient_id}
                    onCloseModal={() => setPatientToDel({})}
                    onClickDelete={() => {deletePatient(patientToDel.patient_id); setPatientToDel({})} }
                    title={(<>{'You’re about to delete patient '}<em>{patientToDel.patient_name}</em></>)}
                />
            )}
            {( !pending &&
            <div className={style.patientsSelectContainer}>
                Patient:
                <Select key={patients} className={style.patientSelect}
                        value={
                            selectedPatient.patient_id ?
                        {label: selectedPatient.patient_name, value: selectedPatient, key: selectedPatient.patient_name}
                                : null
                        }
                        aria-sort={'ascending'}
                        onChange={(newValue) =>
                            {
                                console.log(newValue)
                                setSelectedPatient(JSON.parse(JSON.stringify(newValue.value)))
                            }
                        }
                        options={options}
                />
                {( selectedPatient.patient_id &&
                    <>
                  <NewPrescriptionButton onClick={()=>{setPatientDetails(selectedPatient)}}>Edit Patient</NewPrescriptionButton>
                  <NewPrescriptionButton onClick={()=>{setPatientToDel(selectedPatient)}}>Delete Patient</NewPrescriptionButton>
                    </>
                )}
                <NewPrescriptionButton onClick={()=>{setPatientDetails({new: true})}}>New Patient</NewPrescriptionButton>
            </div>
            )}
            {( selectedPatient.patient_id &&
            <div className={style.patientContainer}>
                <Prescriptions key={selectedPatient.patient_id}
                               patient={selectedPatient}
                               user={props.user}
                               onError={props.onError} />
            </div>
            )}
        </div>
            {( (patientDetails.new || patientDetails.patient_id) &&
                <PatientDetails
                    onCancel={()=>setPatientDetails({})}
                    onPatientAdd={handlePatientAdd}
                    onPatientEdit={handlePatientEdit}
                    onError={props.onError}
                    patient={patientDetails}
                />
            )}
        </>
    )
}

export default Patients