// extracted by mini-css-extract-plugin
export var buttonEditPrescription = "fulfillment-module--button-edit-prescription--CIlKi";
export var buttonRemovePrescription = "fulfillment-module--button-remove-prescription--DSLKc";
export var filterComponent = "fulfillment-module--filter-component--NzWBF";
export var fulfillment = "fulfillment-module--fulfillment--O2qV0";
export var fulfillmentTableHeader = "fulfillment-module--fulfillment-table-header--6yisk";
export var showStatus = "fulfillment-module--show-status--x8PqC";
export var statuscompleted = "fulfillment-module--statuscompleted--JEuXu";
export var statusnew = "fulfillment-module--statusnew--gID9f";
export var statuspacked = "fulfillment-module--statuspacked--Kp2Gz";
export var statuspaid = "fulfillment-module--statuspaid--op1fF";
export var statusshipped = "fulfillment-module--statusshipped--+ODhX";