import React, {useState, useEffect} from "react"
import Sprintf from "sprintf-js";
import DataTable from 'react-data-table-component'
import * as style from "./herbs.module.scss";
import {fetchAcu} from "../fetchacu";
import styled from "styled-components";
import HerbDetails from "./herbDetails/herbDetails";
import HerbBottleDetails from "./herbBottleDetails/herbBottleDetails";
import {caseInsensitiveSort} from "../data-table-utils";
import DeleteDialog from "../deleteDialog/deleteDialog";

const CheckBox = styled.input.attrs({type: "checkbox"})`
    margin-right: 10px;
`;

const FilterLow = (props) => (
    <div className={style.filterLow}>
        <CheckBox id={"checkBrookline"} defaultChecked={props.checkedBrookline} onChange={props.onChangeBrookline}/>
        <label style={{color:'blue'}} htmlFor={"checkBrookline"}>Brookline</label>
        <CheckBox id={"checkDedham"} defaultChecked={props.checkedDedham} onChange={props.onChangeDedham}/>
        <label style={{color:'red'}} htmlFor={"checkDedham"}>Dedham</label>
        <CheckBox id={"checklow"} defaultChecked={props.checked} onChange={props.onChange}/>
        <label htmlFor={"checklow"}>show low only</label>
    </div>
);

const TextField = styled.input`
    height: 34px;
    width: 200px;
      	border-radius: 3px;
      	border-top-left-radius: 5px;
      	border-bottom-left-radius: 5px;
      	border-top-right-radius: 0;
      	border-bottom-right-radius: 0;
      	border: 1px solid #e5e5e5;
      	padding: 0 32px 0 16px;
      
      	&:hover {
        		cursor: pointer;
        	}
      `;
const ClearButton = styled.button`
    	border-top-left-radius: 0;
	    border-bottom-left-radius: 0;
	    border-top-right-radius: 5px;
	    border-bottom-right-radius: 5px;
	    height: 34px;
	    width: 32px;
	    text-align: center;
	    display: flex;
	    align-items: center;
        justify-content: center;
        cursor: pointer;
    `;

const AddHerbButton = styled.button`
    border: outset;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
`;

const FilterComponent = ({ filterText, onFilter, checkedBrookline, onChangeBrookline, checkedDedham, onChangeDedham, filterLow, onFilterLow, onClear, isStockManager }) => (
    <div className={style.filterComponent}>
        {( isStockManager ?
        <FilterLow checked={filterLow} onChange={onFilterLow}
                   checkedBrookline={checkedBrookline} onChangeBrookline={onChangeBrookline}
                   checkedDedham={checkedDedham} onChangeDedham={onChangeDedham}
        />
                :
                <div></div>
        )}
        <TextField
            id="search"
            type="text"
            placeholder="Filter By Name"
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
        />
        <ClearButton type="button" onClick={onClear}>X</ClearButton>
    </div>
);

const conditionalRowStyles = [
    {
        when: row => row.inventory <= row.low_inventory,
        style: {
            backgroundColor: '#ffffcc',
        },
    },
];

const Bottle = (props) => (<div className={style.bottle} key={props.bottleId}>
    <div className={style.label} style={ { background: props.location === 'Dedham' ? 'red' : 'blue'} }></div>
    {props.currentWeight}g. of {props.supplierName}:{props.lot}
    <button onClick={() => props.onClickEdit()} className={style.buttonEditHerb}>&#x270D;</button>
    <button onClick={() => props.onClickDelete()} className={style.buttonRemoveHerb}>x</button>
</div>)

const ExpandedComponent = ({ data }) => (<>
    {( data.bottles.map((bottle) => (
        <Bottle key={bottle.bottle_id}
                bottleId={bottle.bottle_id}
                currentWeight={bottle.current_weight}
                supplierName={bottle.supplier_name}
                onClickEdit={bottle.onClickEdit}
                onClickDelete={bottle.onClickDelete}
                location={bottle.location}
                lot={bottle.lot} />
        ))
    )}
    </>
);



const Herbs = (props) => {

    const [rows, setRows] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [filterLow, setFilterLow] = useState(false);
    const [showBrookline, setShowBrookline] = useState(true)
    const [showDedham, setShowDedham] = useState(true)

    const [showHerbDetails, setShowHerbDetails] = useState({})
    const [showHerbBottleDetails, setShowHerbBottleDetails] = useState({})
    const [pending, setPending] = useState(true);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const [herbToDel, setHerbToDel] = useState({});

    const filteredItems = rows.filter(
        item => ( filterText.length === 0 ? true
            : ( (item.herb_name && item.herb_name.toLowerCase().includes(filterText.toLowerCase()))
                || ( item.common_name && item.common_name.toLowerCase().includes(filterText.toLowerCase()))
                || ( item.herb_code && item.herb_code.toString().includes(filterText.toLowerCase()))
                || ( item.latin_name && item.latin_name.toLowerCase().includes(filterText.toLowerCase()))
            ) ))
        .filter( item => filterLow ? (item.inventory <= item.low_inventory) : true )
//        .filter( item => ( showBrookline && (item.location === "Brookline") || showDedham && (item.location === "Dedham")  ) )
        .map( item => {
            item.bottles = item.bottles.map(
                b => {
                    b.herb_name = item.herb_name;
                    b.onClickEdit = () => {console.log(b); setShowHerbBottleDetails(b)};
                    b.onClickDelete = () => {
                        console.log(b);
                        if ( window.confirm( "Are you really want to delete the herb bottle?")) {
                            handleHerbBottleDelete(b)
                        }
                    };
                    return b
                })
            item.inventory = item.bottles.reduce((s, b) => {s += parseFloat(b.current_weight); return s}, 0.0)
            return item
        });

    const deleteHerb = async (herbCode) => {
        let response = await fetchAcu('stock/herb/' + herbCode, {method: 'DELETE'})
        if ( response.status === 200 ) {
            const okMessage = await response.text()
            setRows( rows.filter( row => (row.herb_code !== herbCode) ))
            console.log("delete herb: " + okMessage)
        } else {
            const errorMessage = await response.text()
            console.log("delete herb error: " + errorMessage)
            props.onError({status: response.status, message: errorMessage})
        }
    };

    const subHeaderComponentMemo = React.useMemo(() => {
            const handleClear = () => {
                if (filterText) {
                    setResetPaginationToggle(!resetPaginationToggle);
                    setFilterText('');
                }
            };
            return (
                <div className={style.herbsTableHeader}>
                    {( (props.user.isStockManager || props.user.isAdmin) ?
                    <AddHerbButton onClick={()=>{setShowHerbDetails({new: 1})}}>New Herb</AddHerbButton>
                            :
                            <div></div>
                    )}
                    <FilterComponent filterLow={filterLow}
                                     onFilterLow={e => setFilterLow(!filterLow) }
                                     onFilter={e => setFilterText(e.target.value)}
                                     onClear={handleClear}
                                     filterText={filterText}
                                     checkedBrookline={showBrookline}
                                     checkedDedham={showDedham}
                                     onChangeBrookline={ e => setShowBrookline(!showBrookline) }
                                     onChangeDedham={ e => setShowDedham(!showDedham)}
                                     isStockManager={props.user.isStockManager || props.user.isAdmin}
                    />
                </div>
            );
        },
        [filterText, filterLow, showBrookline, showDedham, resetPaginationToggle, props.user.isStockManager, props.user.isAdmin]);


    useEffect(() => {
        const locations = []
        if (showBrookline)
            locations.push('Brookline')
        if (showDedham)
            locations.push('Dedham')
        fetchAcu('stock/herbs?locations=' + locations.join(','))
            .then(
                response => {
                    if ( response.status === 200 )
                        response.json().then( data => {setRows(data); setPending(false)})
                    else {
                        response.text().then(err => {console.log(err); props.onError({status: response.status, message: err})})
                    }
                }
            ).catch(err => {
                console.log(err);
                props.onError({status: 1, message: err});
            })
    }, [props, showBrookline, showDedham]);


    const handleOnCreateHerb = (herb) => {
        console.log("CREATE:")
        console.log(herb)
        setRows( () => rows.concat(herb) )
        setShowHerbDetails(() => ({}));
    }
    const handleOnUpdateHerb = (herb) => {
        console.log("UPDATE:")
        console.log(herb)
        const oldHerb = (rows.filter( item => item.herb_code === herb.herb_code ))[0]
        console.log(oldHerb)
        const newRows = rows.filter( item => item.herb_code !== herb.herb_code )
        const newHerb = {...oldHerb, ...herb};
        console.log(newHerb)
        setRows(() => newRows.concat(newHerb) )
        setShowHerbDetails(() => ({}));
    }

    const handleHerbBottleAdd = (bottle) => {
        console.log("add bottle:")
        console.log(bottle)
        const oldHerb = (rows.filter( item => item.herb_code === bottle.herb_code ))[0]
        console.log(oldHerb)
        const newRows = rows.filter( item => item.herb_code !== bottle.herb_code )
        oldHerb.bottles = oldHerb.bottles.concat(bottle)
        setRows( JSON.parse(JSON.stringify(newRows.concat(oldHerb))) )
        setShowHerbBottleDetails(() => ({}));
    }

    const handleHerbBottleEdit = (bottle) => {
        console.log("edit bottle:")
        console.log(bottle)
        const oldHerb = (rows.filter( item => item.herb_code === bottle.herb_code ))[0]
        console.log(oldHerb)
        const newRows = rows.filter( item => item.herb_code !== bottle.herb_code )
        oldHerb.bottles = oldHerb.bottles.filter( b => b.bottle_id !== bottle.bottle_id)
        oldHerb.bottles = oldHerb.bottles.concat(bottle)
        setRows( JSON.parse(JSON.stringify(newRows.concat(oldHerb))) )
        setShowHerbBottleDetails(() => ({}));
    }
    const handleHerbBottleDelete = async (bottle) => {
        let response = await fetchAcu('stock/bottle/' + bottle.bottle_id, {method: 'DELETE'})
        if ( response.status === 200 ) {
            const okMessage = await response.text()
            const oldHerb = (rows.filter( item => item.herb_code === bottle.herb_code ))[0]
            console.log(oldHerb)
            const newRows = rows.filter( item => item.herb_code !== bottle.herb_code )
            oldHerb.bottles = oldHerb.bottles.filter( b => b.bottle_id !== bottle.bottle_id)
            setRows( JSON.parse(JSON.stringify(newRows.concat(oldHerb))) )
            console.log("delete bottle: " + okMessage)
        } else {
            const errorMessage = await response.text()
            console.log("delete bottle error: " + errorMessage)
            props.onError({status: response.status, message: errorMessage})
        }

    }

    const columns = [
        {
            id: 'herbCode',
            name: 'Code',
            selector: row => row.herb_code,
            format: row => Sprintf.sprintf('%03d', row.herb_code),
            sortable: true,
            width: '80px',
        },
        {
            id: 'name',
            name: 'Name',
            selector: row => row.herb_name,
            sortable: true,
            sortFunction: caseInsensitiveSort('herb_name'),
            grow: 2,
            style: {fontSize: '16px'}
        },
        {
            id: 'commonName',
            name: 'Common Name',
            selector: row => row.common_name,
            sortable: true,
            sortFunction: caseInsensitiveSort('common_name'),
            grow: 4,
            style: {fontSize: '16px'}
        },
        {
            id: 'latinName',
            name: 'Latin Name',
            selector: row => row.latin_name,
            sortable: true,
            sortFunction: caseInsensitiveSort('latin_name'),
            grow: 4,
            style: {fontSize: '16px'}
        },
        {
            id: 'gramCost',
            name: 'Gram Cost',
            selector: row => row.gram_cost,
            format: row => Sprintf.sprintf('%.2f', parseFloat(row.gram_cost)),
            sortable: true,
            sortFunction: (a,b) => (parseFloat(a.gram_cost) - parseFloat(b.gram_cost)),
            width: '110px',
        },
        {
            id: 'inventory',
            name: 'Inventory',
            selector: row => row.inventory,
            format: row => Sprintf.sprintf('%.2f', parseFloat(row.inventory)),
            sortable: true,
            sortFunction: (a,b) => (parseFloat(a.inventory) - parseFloat(b.inventory)),
        },
        {
            id: 'lowInventory',
            name: 'Low Margin',
            selector: row => row.low_inventory,
        },
        {
            id: 'allergens',
            name: 'Allergens',
            selector: row => row.allergens.join(', '),
            grow: 2,
        },
        {
            id: 'notes',
            name: 'Notes',
            selector: row => row.notes,
            width: '80px',
        },
        {
            id: 'buttons',
            name: '',
            cell: (row) => (<>
                <button onClick={() => setShowHerbBottleDetails({herb_code: row.herb_code, herb_name: row.herb_name})}
                        className={style.buttonAddHerb}>+</button>
                <button onClick={() => setShowHerbDetails(row)}
                        className={style.buttonEditHerb}>&#x270D;</button>
                <button onClick={() => setHerbToDel(row)}
                        className={style.buttonRemoveHerb}>x</button>
            </>),
            width: '110px',
        },
    ];

    const filteredColumns = columns.filter(
        r => ( (props.user.isStockManager||props.user.isAdmin) || ['herbCode', 'name', 'commonName', 'latinName', 'gramCost', 'allergens'].includes(r.id) )
    )

    const stockmanagerProps = (props.user.isStockManager || props.user.isAdmin) ?
        {
            expandableRows: true,
            expandableRowDisabled: row => row.inventory === 0,
            expandableRowsComponent: ExpandedComponent,
            conditionalRowStyles: conditionalRowStyles,
        }
        : {};
    return (
        <>
         {(
             (showHerbDetails.new || showHerbDetails.herb_code) ?
            <HerbDetails key={showHerbDetails.herb_code}
                         herb={showHerbDetails}
                         user={props.user}
                         onCancel={()=>setShowHerbDetails({})}
                         onCreate={handleOnCreateHerb}
                         onUpdate={handleOnUpdateHerb}
                         onError={props.onError}
            />
            :
                 (<>
        <div className={style.herbs}>
            {( herbToDel.herb_code &&
                <DeleteDialog
                    key={herbToDel.herb_code}
                    onCloseModal={() => setHerbToDel({})}
                    onClickDelete={() => {deleteHerb(herbToDel.herb_code); setHerbToDel({})} }
                    title={(<>{'You’re about to delete herb '}<em>{herbToDel.herb_name}</em></>)}
                    body={(<><p>Before you delete it permanently, there’s some things you should know:</p>
                        <ul>
                            <li>herbalists will not be able to use formulas with this herb</li>
                            <li>{herbToDel.herb_name} herb bottles will not be available from Herbs page</li>
                        </ul>
                    </>)}
                />
            )}
            <DataTable
                columns={filteredColumns}
                keyField={'herbCode'}
                data={filteredItems}
                progressPending={pending}
                persistTableHead
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                pagination
                paginationPerPage={15}
                paginationResetDefaultPage={resetPaginationToggle}
                { ...stockmanagerProps }
                highlightOnHover={true}
                dense={true}
                defaultSortFieldId={'name'}
            />
        </div>
                     {( (showHerbBottleDetails.herb_code || showHerbBottleDetails.bottle_id) &&
                         <HerbBottleDetails
                                         onCancel={()=>setShowHerbBottleDetails({})}
                                         onHerbBottleAdd={handleHerbBottleAdd}
                                         onHerbBottleEdit={handleHerbBottleEdit}
                                         onError={props.onError}
                                         bottle={showHerbBottleDetails}
                         />
                     )}
                     </>)
            )}
            </>
    )
}

export default Herbs