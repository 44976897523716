// extracted by mini-css-extract-plugin
export var currentBalance = "revenue-module--current-balance--NSZu7";
export var datepickerBar = "revenue-module--datepicker-bar--ZwA69";
export var datepickerFrom = "revenue-module--datepicker-from--JCSOs";
export var datepickerTo = "revenue-module--datepicker-to--vphaJ";
export var left = "revenue-module--left--LAWnG";
export var revenue = "revenue-module--revenue--ksY9w";
export var revenueBar = "revenue-module--revenue-bar--ufb9O";
export var revenueItem = "revenue-module--revenueItem--BK3-Q";
export var right = "revenue-module--right--84J70";
export var twocolumns = "revenue-module--twocolumns--g76Sh";