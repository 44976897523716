import React, {useState,useEffect} from "react";
import styled from "styled-components";
import * as style from "./applicantDetails.module.scss"
import {fetchAcu} from "../../fetchacu";
//import {useEffect} from "@types/react";

const Button = styled.button`
    border: outset;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    margin: 20px;
`;

const SubmitButton = styled.input.attrs({type: 'submit'})`
    border: outset;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    margin: 20px;
`;

const CheckBox = styled.input.attrs({type: "checkbox"})`
  margin-right: 10px;
`;

const ApplicantDetails = (props) => {
    const [pending, setPending] = useState(true)
    const [userName, setUserName] = useState(props.applicant.first_name + ' ' + props.applicant.last_name)
    const [notes, setNotes] = useState('')
    const [email, setEmail] = useState(props.applicant.email)
//    const [emailVerified, setEmailVerified] = useState(props.herbalist.email_verified)
//    const emailVerified = props.herbalist.email_verified
    const [isAdmin, setIsAdmin] = useState(false)
    const [isHerbalist, setIsHerbalist] = useState(true)
    const [isStockManager, setIsStockmanager] = useState(false)
    const [ownPrices, setOwnPrices] = useState(true)
    const [fulfillmentFee, setFulfillmentFee] = useState(3.9)
    const [password, setPassword] = useState('')
    const [applicant, setApplicant] = useState({})

    useEffect(() => {
        fetchAcu('admin/applicant/' + props.applicant.reg_uuid)
            .then(
                response => {
                    if ( response.status === 200 )
                        response.json().then( data => {setApplicant(data); setPending(false)})
                    else {
                        response.text().then(err => {console.log(err); props.onError({status: response.status, message: err})})
                    }
                }
            ).catch(err => {
            console.log(err);
            props.onError({status: 1, message: err});
        })
    }, [props]);

    const submitHandler = async e => {
        e.preventDefault()

        let herbalist = {
            reg_uuid: props.applicant.reg_uuid,
            roles: []
        };
        herbalist.herbalist_name = userName
        herbalist.notes = notes
        herbalist.email = email
        herbalist.password = password
        if (isHerbalist) {
            herbalist.roles.push('herbalist')
            herbalist.fulfillment_fee = parseFloat(fulfillmentFee)
            herbalist.own_prices = !!ownPrices
        }
        // if (emailVerified !== props.herbalist.email_verified)
        //     herbalist.email_verified = emailVerified
        if (isStockManager)
            herbalist.roles.push('stockmanager')
        if (isAdmin)
            herbalist.roles.push('admin')

        console.log(herbalist)

        let response = await fetchAcu('admin/herbalist', {method: 'POST', body: JSON.stringify(herbalist)})
        if (response.status !== 200) {
            let errorMessage = await response.text()
            console.log(errorMessage)
            props.onError({status: response.status, message: errorMessage})
        } else {
            let data = await response.json()
            console.log(data)
            herbalist.herbalist_id = data.herbalist_id;
            delete herbalist.password
            herbalist = {...props.herbalist, ...herbalist}
            props.onCreate(herbalist)
        }

        console.log('user submitted')

    }

    const endPoint = `${process.env.GATSBY_API_URL}` || '/api/v1';

    return (
        <div className={style.applicantPage}>
            <h2 className={style.applicantHeader}>
                Application Details
            </h2>
        <div className={style.applicantContainer}>
        <form onSubmit={submitHandler}>
            <div className={style.userDetails}>
                <div className={style.userDetailsContent}>
                        <div className={style.userDetailsContentRow}>
                            <div>{"Reg UUID:"}</div>
                            <div><input type={'text'} disabled={'disabled'} value={props.applicant.reg_uuid} /></div>
                        </div>
                    <div className={style.userDetailsContentRow}>
                        <div>{"User Name:"}</div>
                        <div><input type={'text'} defaultValue={userName}
                                    required={true}
                                    onChange={e => setUserName(e.target.value)} /></div>
                    </div>
                    <div className={style.userDetailsContentRow}>
                        <div>{"Email:"}</div>
                        <div><input type={'text'} defaultValue={email}
                                    required={true}
                                    onChange={e => setEmail(e.target.value)} /></div>
                    </div>
                    <div className={style.userDetailsContentRow}>
                        <div>{"Password:"}</div>
                        <div><input type={'text'} defaultValue={''}
                                    placeholder={'Fill in to set new password'}
                                    required={true}
                                    onChange={e => setPassword(e.target.value)} /></div>
                    </div>
                    <div className={style.userDetailsContentRow}>
                        <div>{"Roles:"}</div>
                        <div>
                            <div className={style.showClassic}>
                                <CheckBox id={"isAdmin"} defaultChecked={isAdmin}
                                          onChange={
                                              () => setIsAdmin(!isAdmin)
                                          }/>
                                <label htmlFor={"isAdmin"}>Admin</label>
                            </div>
                            <div className={style.showClassic}>
                                <CheckBox id={"isHerbalist"} defaultChecked={isHerbalist} onChange={
                                    () => setIsHerbalist(!isHerbalist)
                                }/>
                                <label htmlFor={"isHerbalist"}>Herbalist</label>
                            </div>
                            <div className={style.showClassic}>
                                <CheckBox id={"isStockManager"} defaultChecked={isStockManager} onChange={
                                    () => setIsStockmanager(!isStockManager)
                                }/>
                                <label htmlFor={"isStockManager"}>Stock Manager</label>
                            </div>
                        </div>
                    </div>
                    {( isHerbalist &&
                        <>
                            <div className={style.userDetailsContentRow}>
                                <div>{"Fulfillment fee:"}</div>
                                <div><input type={'number'} defaultValue={fulfillmentFee}
                                            required={true} step={0.01} min={0}
                                            onChange={e => setFulfillmentFee(e.target.value)} /></div>
                            </div>
                            <div className={style.userDetailsContentRow}>
                                <div>{"Own prices:"}</div>
                                <div><CheckBox id={"isOwnPrices"} defaultChecked={ownPrices}
                                               onChange={()=>setOwnPrices(!ownPrices)}
                                /></div>
                            </div>
                        </>
                    )}
                    <div className={style.userDetailsContentRow}>
                        <div>{"Notes:"}</div>
                        <div><textarea onChange={ e => setNotes(e.target.value) } value={notes} /></div>
                    </div>
                </div>

                <div className={style.userDetailsFooter}>
                    { !applicant.herbalist_id && (
                        <SubmitButton
                            value={ "Create Herbalist" }
                        />
                    )}
                    <Button onClick={(e) => { e.preventDefault(); props.onCancel()}}>Cancel</Button>
                    { !applicant.herbalist_id && (
                        <Button onClick={(e) => { e.preventDefault(); props.onDecline() }}>Decline</Button>
                    )}
                </div>
            </div>
        </form>
            <div className={style.applicantDetails}>
                <div className={style.applicantDetailsRow}>
                    <div>email</div><div>{applicant.email}</div>
                </div>
                <div className={style.applicantDetailsRow}>
                    <div>phone</div><div>{applicant.phone}</div>
                </div>
                <div className={style.applicantDetailsRow}>
                    <div>salutation</div><div>{applicant.salutation}</div>
                </div>
                <div className={style.applicantDetailsRow}>
                    <div>first_name</div><div>{applicant.first_name}</div>
                </div>
                <div className={style.applicantDetailsRow}>
                    <div>middle_name</div><div>{applicant.middle_name}</div>
                </div>
                <div className={style.applicantDetailsRow}>
                    <div>last_name</div><div>{applicant.last_name}</div>
                </div>
                <div className={style.applicantDetailsRow}>
                    <div>suffix</div><div>{applicant.suffix}</div>
                </div>
                <div className={style.applicantDetailsRow}>
                    <div>license_number</div><div>{applicant.license_number}</div>
                </div>
                <div className={style.applicantDetailsRow}>
                    <div>national_association</div><div>{applicant.national_association}</div>
                </div>
                <div className={style.applicantDetailsRow}>
                    <div>state_association</div><div>{applicant.state_association}</div>
                </div>
                <div className={style.applicantDetailsRow}>
                    <div>school</div><div>{applicant.school}</div>
                </div>
                <div className={style.applicantDetailsRow}>
                    <div>practice_type</div><div>{applicant.practice_type}</div>
                </div>
                <div className={style.applicantDetailsRow}>
                    <div>practice_credential</div><div>{applicant.practice_credential}</div>
                </div>
                <div className={style.applicantDetailsRow}>
                    <div>practice_clinic</div><div>{applicant.practice_clinic}</div>
                </div>
                <div className={style.applicantDetailsRow}>
                    <div>practice_state</div><div>{applicant.practice_state}</div>
                </div>
                <div className={style.applicantDetailsRow}>
                    <div>specialities</div><div>{applicant.specialities && applicant.specialities.join(', ')}</div>
                </div>
                { applicant.files && applicant.files.map( (file) => {
                      return (<div className={style.applicantDetailsRow}>
                          <div>file</div><div><a href={`${endPoint}admin/applicantfile/${props.applicant.reg_uuid}?fileName=${file.file_name}`}>{file.file_name}</a></div>
                    </div>)
                    })
                }
            </div>
        </div>
        </div>
    );

}

export default ApplicantDetails