// extracted by mini-css-extract-plugin
export var bottle = "herbs-module--bottle--LKLR-";
export var buttonAddBox = "herbs-module--button-add-box--uAieq";
export var buttonAddHerb = "herbs-module--button-add-herb--2nzzP";
export var buttonEditHerb = "herbs-module--button-edit-herb--ybXt8";
export var buttonRemoveHerb = "herbs-module--button-remove-herb--+xNR5";
export var filterComponent = "herbs-module--filter-component--BlmgK";
export var filterLow = "herbs-module--filter-low--9TiHK";
export var herbs = "herbs-module--herbs--1mA6s";
export var herbsTableHeader = "herbs-module--herbs-table-header--os4-e";
export var herbsTitle = "herbs-module--herbs-title--FVdpv";
export var label = "herbs-module--label--dSfSO";