import React, {useEffect, useState} from "react"
import * as style from "./users.module.scss"
import {caseInsensitiveSort} from "../data-table-utils";
import DataTable from "react-data-table-component";
import {fetchAcu} from "../fetchacu";
import styled from "styled-components";
import DeleteDialog from "../deleteDialog/deleteDialog";
import UserDetails from "./userDetails/userDetails";

const AddUserButton = styled.button`
    border: outset;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
`;

const Users = (props) => {
    const [rows, setRows] = useState([])
    const [pending, setPending] = useState(true)
//    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [showUserDetails, setShowUserDetails] = useState({})
    const [userToDel, setUserToDel] = useState({})

    const deleteUser = async (herbalistId) => {
        let response = await fetchAcu('admin/herbalist/' + herbalistId, {method: 'DELETE'})
        if ( response.status === 200 ) {
            const okMessage = await response.text()
            setRows( rows.filter( row => (row.herbalist_id !== herbalistId) ))
            console.log("delete user: " + okMessage)
        } else {
            const errorMessage = await response.text()
            console.log("delete user error: " + errorMessage)
            props.onError({status: response.status, message: errorMessage})
        }
    };

    const switchUser = async (herbalistId) => {
        let response = await fetchAcu('admin/switch/' + herbalistId, {method: 'GET'})
        if ( response.status === 200 ) {
            const switched = await response.json()
            console.log("switch user: ", switched)
            localStorage.setItem('user', JSON.stringify(switched.user))
            localStorage.setItem('accessToken', switched.accessToken)
            localStorage.setItem('refreshToken', switched.refreshToken)
            props.onSwitch(switched.user)
        } else {
            const errorMessage = await response.text()
            console.log("switch user error: " + errorMessage)
            props.onError({status: response.status, message: errorMessage})
        }
    }

    useEffect(() => {
        fetchAcu('admin/users')
            .then(
                response => {
                    if ( response.status === 200 )
                        response.json().then( data => {setRows(data); setPending(false)})
                    else {
                        response.text().then(err => {console.log(err); props.onError({status: response.status, message: err})})
                    }
                }
            ).catch(err => {
            console.log(err);
            props.onError({status: 1, message: err});
        })
    }, [props]);

    const handleOnCreateUser = (herbalist) => {
        setRows(rows.concat(herbalist))
        setShowUserDetails({})
    }
    const handleOnUpdateUser = (herbalist) => {
        setRows(rows.filter(r => r.herbalist_id !== herbalist.herbalist_id).concat(herbalist))
        setShowUserDetails({})
    }
    const subHeaderComponentMemo = React.useMemo(() => {
            return (
                <div className={style.usersTableHeader}>
                    <AddUserButton onClick={()=>{setShowUserDetails({new: 1, roles: []})}}>New User</AddUserButton>
                </div>
            );
        },
        []);


    const columns = [
        {
            id: "userId",
            name: "ID",
            selector: row => row.herbalist_id,
            sortable: true,
            width: '80px',
        },
        {
            id: 'userName',
            name: 'Name',
            selector: row => row.herbalist_name,
            sortFunction: caseInsensitiveSort('herbalist_name'),
        },
        {
            id: 'email',
            name: 'Email',
            selector: row => row.email,
            sortFunction: caseInsensitiveSort('email'),
        },
        {
            id: 'roles',
            name: 'Roles',
            selector: row => row.roles,
            format: row => row.roles.join(', ')
        },
        {
            id: 'company',
            name: "Company",
            selector: row => row.company_name,
        },
        {
            id: 'balance',
            name: "Balance",
            selector: row => row.balance,
        },
        {
            id: 'buttons',
            name: '',
            cell: (row) => (<>
                {( row.herbalist_id !== props.user.id &&
                <button onClick={() => switchUser(row.herbalist_id)}
                        title={'Login as ' + row.herbalist_name}
                        className={style.buttonEditUser}>&#x1F576;</button>
                )}
                <button onClick={() => setShowUserDetails(row)}
                        title={'Edit ' + row.herbalist_name}
                        className={style.buttonEditUser}>&#x270D;</button>
                {( row.herbalist_id !== props.user.id &&
                <button onClick={() => setUserToDel(row)}
                        title={'Delete ' + row.herbalist_name}
                        className={style.buttonRemoveUser}>x</button>
                )}
            </>),
            width: '130px',
        },
    ]

    return (
        <>
            {(
                ( showUserDetails.new || showUserDetails.herbalist_id )
                ?
                <UserDetails herbalist={showUserDetails}
                             user={props.user}
                             onCancel={()=>setShowUserDetails({})}
                             onCreate={handleOnCreateUser}
                             onUpdate={handleOnUpdateUser}
                             onError={props.onError}
                />
                :
        <div className={style.users}>
            {( userToDel.herbalist_id &&
                <DeleteDialog
                    key={userToDel.herbalist_id}
                    onCloseModal={() => setUserToDel({})}
                    onClickDelete={() => {deleteUser(userToDel.herbalist_id); setUserToDel({})} }
                    title={(<>{'You’re about to delete user '}<em>{userToDel.herbalist_name}</em></>)}
                />
            )}
            <DataTable
                keyField={'userId'}
                columns={columns}
                data={rows}
                progressPending={pending}
                persistTableHead
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                pagination
                paginationPerPage={10}
                //paginationResetDefaultPage={resetPaginationToggle}
                //                expandableRows
                //                expandableRowDisabled={row => row.inventory === 0}
                //                expandableRowsComponent={ExpandedComponent}
                highlightOnHover={true}
                //                conditionalRowStyles={conditionalRowStyles}
                // dense={true}
                defaultSortFieldId={'userName'}
                defaultSortAsc={false}
            />
        </div>
                )}
        </>
    )
}

export default Users