import React, {useState} from "react";
import * as style from "./prescriptionDetails.module.scss"
import styled from "styled-components";
import FormulaDetails from "../../formulaDetails/formulaDetails";
import Select from "react-select";
import {fetchAcu} from "../../fetchacu";

const Button = styled.button`
    border: outset;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    margin: 20px;
`;

const SubmitButton = styled.input.attrs({type: 'submit'})`
    border: outset;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    margin: 20px;
`;

const selectOptions = [
    {label: 'new', value: 'new'},
    {label: 'paid', value: 'paid'},
    {label: 'packed', value: 'packed'},
    {label: 'shipped', value: 'shipped'},
    {label: 'completed', value: 'completed'},
]

const PrescriptionDetails = (props) => {
    const [status, setStatus] = useState(props.prescription.status)
    const [trackingCode, setTrackingCode] = useState(props.prescription.tracking_code)
    const [markup, setMarkup] = useState(props.prescription.markup)
    const changed = (status !== props.prescription.status)
        || ( trackingCode !== props.prescription.tracking_code )
        || ( markup !== props.prescription.markup )
    const submitHandler = async (e) => {
        e.preventDefault()
        if (!changed)
            return props.onCancel()

        console.log(status)
        console.log(trackingCode)

        const upd = {}
        if ( status !== props.prescription.status )
            upd.status = status
        if ( trackingCode !== props.prescription.tracking_code )
            upd.tracking_code = trackingCode
        if ( markup !== props.prescription.markup )
            upd.markup = markup

        let response = await fetchAcu('stock/prescription/' + props.prescription.prescription_id, {method: 'PUT', body: JSON.stringify(upd)})
        if (response.status === 200) {
            const okMessage = await response.text()
            console.log("Update prescription: " + okMessage)
            upd.status = status
            upd.tracking_code = trackingCode
            upd.prescription_id = props.prescription.prescription_id
            props.onPrescriptionUpdate(upd)
        } else {
            const errorMessage = await response.text()
            console.log("Update prescription error: " + errorMessage)
            props.onError({status: response.status, message: errorMessage})
        }
    }

    const onPrescriptionPack = (prescriptionId) => {
        const prescription = JSON.parse(JSON.stringify(props.prescription))
        prescription.status='packed';
        console.log("PACKED", prescription)
        props.onPrescriptionUpdate(prescription)
    }

    const totalPrice = Math.round( 100.0 * (parseFloat(props.prescription.formula_cost)
        * parseFloat(props.prescription.multiplicator)
        * parseFloat(markup)
        + parseFloat(props.prescription.fulfillment_fee)
        + parseFloat(props.prescription.delivery_cost))
         ) / 100;

    return (
        <form onSubmit={submitHandler}>
            <div className={style.prescriptionDetailsContainer}>
                <div className={style.prescriptionDetailsHeader}>
                    <h2>Prescription Fulfillment</h2>
                </div>
                <div className={style.prescriptionDetailsColumns}>
                    <div className={style.prescriptionDetailsContentLeft}>
                        <div className={style.prescriptionDetailsContentRow}>
                            <div className={style.prescriptionDetailsContentRowLeft}>
                                <div>{"Prescription ID:"}</div>
                                <div>{props.prescription.prescription_id}</div>
                            </div>
                            <div className={style.prescriptionDetailsContentRowLeft}>
                                <div>Created:</div>
                                <div>{props.prescription.date}</div>
                            </div>
                        </div>
                        <div className={style.prescriptionDetailsContentRow}>
                            <div className={style.prescriptionDetailsContentRowLeft}>
                                <div>{"Patient:"}</div>
                                <div>{props.prescription.patient_name}</div>
                            </div>
                            <div className={style.prescriptionDetailsContentRowLeft}>
                                <div>Herbalist:</div>
                                <div>{props.prescription.herbalist_name}</div>
                            </div>
                        </div>
                        <div className={style.prescriptionDetailsContentRow}>
                            <div className={style.prescriptionDetailsContentRowLeft}>
                                <div>{"Payment:"}</div>
                                <div>{props.prescription.payment_method}</div>
                            </div>
                            <div className={style.prescriptionDetailsContentRowLeft}>
                                <div>Delivery:</div>
                                <div>{props.prescription.delivery_method}</div>
                            </div>
                        </div>
                        <div className={style.prescriptionDetailsContentRow}>
                            <div className={style.prescriptionDetailsContentRowLeft}>
                                <div>{"Formula:"}</div>
                                <div>{props.prescription.formula_name}</div>
                            </div>
                            <div className={style.prescriptionDetailsContentRowLeft}>
                                <div>Formula grams:</div>
                                <div>{props.prescription.formula_weight}g</div>
                            </div>
                        </div>
                        <div className={style.prescriptionDetailsContentRow}>
                            <div className={style.prescriptionDetailsContentRowLeft}>
                                <div>{"Total grams:"}</div>
                                <div>{props.prescription.total_grams}g</div>
                            </div>
                            <div className={style.prescriptionDetailsContentRowLeft}>
                                <div>Multiplication Factor:</div>
                                <div>x{props.prescription.multiplicator}</div>
                            </div>
                        </div>
                        <div className={style.prescriptionDetailsContentRow}>
                            <div>{"Herbs cost:"}</div>
                            <div>{ Math.round(100.0 * parseFloat(props.prescription.formula_cost) * parseFloat(props.prescription.multiplicator))/100}</div>
                        </div>
                        <div className={style.prescriptionDetailsContentRow}>
                            <div>{"Fulfillment fee:"}</div>
                            <div>{ props.prescription.fulfillment_fee }</div>
                        </div>
                        <div className={style.prescriptionDetailsContentRow}>
                            <div>{"Delivery cost:"}</div>
                            <div>{ props.prescription.delivery_cost }</div>
                        </div>
                        <div className={style.prescriptionDetailsContentRow}>
                            <div>{"Markup:"}</div>
                            <div>x<input type={'number'} step={0.1}  min={0.1} defaultValue={props.prescription.markup}
                                         readOnly={ props.prescription.status !== "new" }
                                        onChange={e => setMarkup(e.target.value)}
                            /></div>
                        </div>
                        <div className={style.prescriptionDetailsContentRow}>
                            <div>{"Total price:"}</div>
                            <div>{ totalPrice }</div>
                        </div>
                        <div className={style.prescriptionDetailsContentRow}>
                            <div className={style.prescriptionDetailsContentRowLeft}>
                                <div>{"Paid:"}</div>
                                <div>{props.prescription.paid_at || '-'}</div>
                            </div>
                            <div className={style.prescriptionDetailsContentRowLeft}>
                                <div>Packed:</div>
                                <div>{props.prescription.packed_at || '-'}</div>
                            </div>
                        </div>
                        <div className={style.prescriptionDetailsContentRow}>
                            <div className={style.prescriptionDetailsContentRowLeft}>
                                <div>{"Shipped:"}</div>
                                <div>{props.prescription.shipped_at || '-'}</div>
                            </div>
                            <div className={style.prescriptionDetailsContentRowLeft}>
                                <div>Completed:</div>
                                <div>{props.prescription.completed_at || '-'}</div>
                            </div>
                        </div>
                        <div className={style.prescriptionDetailsContentRow}>
                            <div>{"Tracking code:"}</div>
                            <div><input type={'text'} defaultValue={props.prescription.tracking_code}
                                        onChange={e => setTrackingCode(e.target.value)}
                            /></div>
                        </div>
                        {(
                        props.prescription.shipping_details ?
                        <div className={style.prescriptionDetailsShippingRow}>
                            <div>{"Shipping details"}</div>
                            <p><strong>Shipping rate paid:</strong> <span>${props.prescription.shipping_cost}</span></p>
                            <p><strong>To:</strong> <span>{props.prescription.shipping_details.name}</span></p>
                            <p>
                                <span>{props.prescription.shipping_details.address.country}</span>
                                <span>{props.prescription.shipping_details.address.postal_code}</span>
                                <span>{props.prescription.shipping_details.address.state}</span>
                                <span>{props.prescription.shipping_details.address.city}</span>
                                <span>{props.prescription.shipping_details.address.line1}</span>
                                <span>{props.prescription.shipping_details.address.line2}</span>
                            </p>
                        </div>
                          : <div></div>
                        )}
                        <div className={style.prescriptionDetailsContentRow}>
                            <div>{"Notes:"}</div>
                            <div className={style.paddingLeft}>{props.prescription.notes}</div>
                        </div>
                        <div className={style.prescriptionDetailsContentRow}>
                            <div>{"Status:"}</div>
                            <div><Select className={style.selectStatus}
                                         defaultValue={ { label: props.prescription.status, value: props.prescription.status } }
                                         options={selectOptions}
                                         autoFocus={false}
                                         blurInputOnSelect={true}
                                         isSearchable={false}
                                         closeMenuOnSelect={true}
                                         onChange={newValue => setStatus(newValue.value)}
                            />
                            </div>
                        </div>
                        <div className={style.prescriptionDetailsFooter}>
                            <SubmitButton value={ "Save" }/>
                            <Button onClick={() => { props.onCancel(); return false}}>Cancel</Button>
                        </div>
                    </div>
                    <div className={style.prescriptionDetailsContentRight}>
                        <FormulaDetails key={props.prescription.formula_id}
                                        formulaId={props.prescription.formula_id}
                                        onError={props.onError}
                                        showPrint={true}
                                        showPack={props.prescription.status === "paid"}
                                        prescriptionId={props.prescription.prescription_id}
                                        onPrescriptionPack={onPrescriptionPack}
                                        multiplicator={props.prescription.multiplicator}
                                        patientName={props.prescription.patient_name}
                                        notes={props.prescription.notes}
                                        totalGrams={props.prescription.total_grams}
                                        date={props.prescription.date}
                        />
                    </div>
                </div>
            </div>
        </form>
    )
}

export default PrescriptionDetails