import React, {useEffect, useState} from "react"
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as style from "./revenue.module.scss";
import {fetchAcu} from "../fetchacu";

const ExpandedComponent = (data) => {
    const [prescription, setPrescription] = useState({})
    const [pending, setPending] = useState(true)

    useEffect(() => {
        fetchAcu('office/prescription/' + data.data.prescription_id  )
            .then(
                response => {
                    if ( response.status === 200 )
                        response.json().then( dat => {
                            setPrescription(dat);
                            setPending(false);
                        })
                    else {
                        response.text().then(err => {console.log(err)})
                    }
                }
            ).catch(err => {
            console.log(err)
            });
    }, [data]);

    return (
        <>{ pending
                ?
            (<pre>pending...</pre>)
                :
            (<div>
                    <div class={style.twocolumns}>
                        <div className={style.left}>
                            <p>Prescription ID:</p>
                            <p>Created at:</p>
                            <p>Patient:</p>
                            <p>Formula:</p>
                            <p>Payment</p>
                            <p>Delivery</p>
                            <p>Herbs Cost</p>
                            <p>Markup</p>
                            <p>Delivery Cost</p>
                            <p>Fulfillment Fee</p>
                            <p>Prescription Price</p>
                        </div>
                        <div className={style.right}>
                            <p>{prescription.prescription_id}</p>
                            <p>{prescription.ctime.substring(0,10)}</p>
                            <p>{prescription.patient_name}</p>
                            <p>{prescription.formula.formula_name}</p>
                            <p>{prescription.payment_method}</p>
                            <p>{prescription.delivery_method}</p>
                            <p>${prescription.herbs_cost}</p>
                            <p>{prescription.markup}</p>
                            <p>${prescription.delivery_cost}</p>
                            <p>${prescription.fulfillment_fee}</p>
                            <p>${prescription.prescription_price}</p>
                        </div>
                    </div>
                </div>
            )
        }
        </>
    )
};

const Revenue = (props) => {
    const [rows, setRows] = useState([])
    const [pending, setPending] = useState(true)
    const [revenueForPeriod, setRevenueForPeriod] = useState('')
    const [herbsCostForPeriod, setHerbsCostForPeriod] = useState('')
    const [dateFrom, setDateFrom] = useState(new Date())
    const [dateTo, setDateTo] = useState(new Date())
    const columns = [
        {
            id: "date",
            name: "date",
            selector: row => row.date,
        },
        {
            id: 'herbalist',
            name: 'Herbalist',
            selector: row => row.herbalist,
        },
        {
            id: 'type',
            name: 'Type',
            selector: row => row.type,
        },
        {
            id: 'herbs_cost',
            name: 'Herbs Cost',
            selector: row => row.herbs_cost,
            cell: row => {
                return '$' + row.herbs_cost;
            },
        },
        {
            id: 'revenue',
            name: 'Revenue',
            selector: row => row.revenue,
            cell: row => {
                return '$' + row.revenue;
            },
        },
    ]

    useEffect(() => {
        fetchAcu('admin/revenue?from=' + dateFrom.toISOString().split('T')[0] + '&to=' + dateTo.toISOString().split('T')[0]  )
            .then(
                response => {
                    if ( response.status === 200 )
                        response.json().then( data => {
                            setRevenueForPeriod(data.revenue_for_the_period);
                            setHerbsCostForPeriod(data.herbs_cost_for_the_period);
                            setRows(data.rows);
                            setPending(false);
                        })
                    else {
                        response.text().then(err => {console.log(err); props.onError({status: response.status, message: err})})
                    }
                }
            ).catch(err => {
            console.log(err);
            props.onError({status: 1, message: err});
        })
    }, [props, dateFrom, dateTo]);


    return (
        <>
        <div className={style.revenue}>
            <h2>Revenue Report</h2>
            <div className={style.datepickerBar}>
                <div className={style.datepickerFrom}>
                    From: <DatePicker selected={dateFrom} onChange={(date) => setDateFrom(date)} />
                </div>
                <div className={style.datepickerTo}>
                    To: <DatePicker selected={dateTo} onChange={(date) => setDateTo(date)} />
                </div>
            </div>
            <div className={style.datepickerBar}>
                <div className={style.revenueItem}>Revenue for the period: ${ revenueForPeriod } </div>
                <div className={style.revenueItem}>Herbs cost for the period: ${ herbsCostForPeriod } </div>
            </div>
            <DataTable
                // keyField={'userId'}
                columns={columns}
                data={rows}
                progressPending={pending}
                expandableRows
                expandableRowsComponent={ExpandedComponent}
                //persistTableHead
                // subHeader
                // subHeaderComponent={subHeaderComponentMemo}
                //pagination
                //paginationPerPage={10}
                //highlightOnHover={true}
                // dense={true}
            />
        </div>
        </>
    )
}
export default Revenue