// extracted by mini-css-extract-plugin
export var addComponentContainer = "newFormula-module--add-component-container--WPN4u";
export var addPartsButton = "newFormula-module--add-parts-button--yvUsl";
export var addPartsInput = "newFormula-module--add-parts-input--4Pd8a";
export var allergens = "newFormula-module--allergens--lwtRC";
export var buttonRemoveComponent = "newFormula-module--button-remove-component--UfA61";
export var herbSelect = "newFormula-module--herb-select--Syda1";
export var newFormulaColumns = "newFormula-module--new-formula-columns--Ln6uJ";
export var newFormulaContainer = "newFormula-module--new-formula-container--WEgC8";
export var newFormulaContent = "newFormula-module--new-formula-content--qyH6o";
export var newFormulaContentRow = "newFormula-module--new-formula-content-row--he3Gy";
export var newFormulaFooter = "newFormula-module--new-formula-footer--esBpa";
export var newFormulaHeader = "newFormula-module--new-formula-header--OyS6p";
export var parts = "newFormula-module--parts--d592m";