import React, {useState} from "react";
import styled from "styled-components";
import * as style from "./userDetails.module.scss"
import {fetchAcu} from "../../fetchacu";

const Button = styled.button`
    border: outset;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    margin: 20px;
`;

const SubmitButton = styled.input.attrs({type: 'submit'})`
    border: outset;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    margin: 20px;
`;

const CheckBox = styled.input.attrs({type: "checkbox"})`
  margin-right: 10px;
`;

const UserDetails = (props) => {
    const [userName, setUserName] = useState(props.herbalist.herbalist_name)
    const [notes, setNotes] = useState(props.herbalist.notes)
    const [email, setEmail] = useState(props.herbalist.email)
//    const [emailVerified, setEmailVerified] = useState(props.herbalist.email_verified)
    const emailVerified = props.herbalist.email_verified
    const [isAdmin, setIsAdmin] = useState(props.herbalist.roles.includes('admin'))
    const [isHerbalist, setIsHerbalist] = useState(props.herbalist.roles.includes('herbalist'))
    const [isStockManager, setIsStockmanager] = useState(props.herbalist.roles.includes('stockmanager'))
    const [ownPrices, setOwnPrices] = useState(props.herbalist.own_prices)
    const [fulfillmentFee, setFulfillmentFee] = useState(props.herbalist.fulfillment_fee)
    const [password, setPassword] = useState('')

    const submitHandler = async e => {
        e.preventDefault()

        let herbalist = {
            roles: []
        };
        if (userName !== props.herbalist.herbalist_name)
            herbalist.herbalist_name = userName
        if (notes !== props.herbalist.notes)
            herbalist.notes = notes
        if (email !== props.herbalist.email)
            herbalist.email = email
        if (password)
            herbalist.password = password
        if (isHerbalist) {
            herbalist.roles.push('herbalist')
            herbalist.fulfillment_fee = parseFloat(fulfillmentFee)
            herbalist.own_prices = !!ownPrices
        }
        if (emailVerified !== props.herbalist.email_verified)
            herbalist.email_verified = emailVerified
        if (isStockManager)
            herbalist.roles.push('stockmanager')
        if (isAdmin)
            herbalist.roles.push('admin')
        if (!isAdmin && props.herbalist.herbalist_id && props.herbalist.herbalist_id === props.user.id )
            herbalist.roles.push('admin')

        console.log(herbalist)

        if ( props.herbalist.herbalist_id  ) {
            let response = await fetchAcu('admin/herbalist/' +  props.herbalist.herbalist_id,
                {method: 'PUT', body: JSON.stringify(herbalist)})
            if (response.status !== 200) {
                let errorMessage = await response.text()
                console.log(errorMessage)
                props.onError({status: response.status, message: errorMessage})
            } else {
                let okMessage = await response.text()
                console.log(okMessage)
                herbalist.herbalist_id = props.herbalist.herbalist_id;
                delete herbalist.password
                herbalist = {...props.herbalist, ...herbalist}
                props.onUpdate(herbalist)
            }
        } else {
            let response = await fetchAcu('admin/herbalist', {method: 'POST', body: JSON.stringify(herbalist)})
            if (response.status !== 200) {
                let errorMessage = await response.text()
                console.log(errorMessage)
                props.onError({status: response.status, message: errorMessage})
            } else {
                let data = await response.json()
                console.log(data)
                herbalist.herbalist_id = data.herbalist_id;
                delete herbalist.password
                herbalist = {...props.herbalist, ...herbalist}
                props.onCreate(herbalist)
            }
        }
        console.log('user submitted')

    }

    return (
        <form onSubmit={submitHandler}>
            <div className={style.userDetails}>
                <div className={style.userDetailsHeader}>
                    <h2>
                        { props.herbalist.herbalist_id ? "Edit User" : "New User" }
                    </h2>
                </div>
                <div className={style.userDetailsContent}>
                    { props.herbalist.herbalist_id && (
                        <div className={style.userDetailsContentRow}>
                            <div>{"User ID:"}</div>
                            <div><input type={'text'} disabled={'disabled'} value={props.herbalist.herbalist_id} /></div>
                        </div>
                    )}
                    <div className={style.userDetailsContentRow}>
                        <div>{"User Name:"}</div>
                        <div><input type={'text'} defaultValue={props.herbalist.herbalist_name}
                                    required={true}
                                    onChange={e => setUserName(e.target.value)} /></div>
                    </div>
                    <div className={style.userDetailsContentRow}>
                        <div>{"Email:"}</div>
                        <div><input type={'text'} defaultValue={props.herbalist.email}
                                    required={true}
                                    onChange={e => setEmail(e.target.value)} /></div>
                    </div>
                    <div className={style.userDetailsContentRow}>
                        <div>{"Password:"}</div>
                        <div><input type={'text'} defaultValue={''}
                                    placeholder={'Fill in to set new password'}
                                    required={!props.herbalist.herbalist_id}
                                    onChange={e => setPassword(e.target.value)} /></div>
                    </div>
                    <div className={style.userDetailsContentRow}>
                        <div>{"Roles:"}</div>
                        <div>
                            <div className={style.showClassic}>
                                <CheckBox id={"isAdmin"} defaultChecked={isAdmin}
                                disabled={ props.herbalist.herbalist_id === props.user.id }
                                          onChange={
                                    () => setIsAdmin(!isAdmin)
                                }/>
                                <label htmlFor={"isAdmin"}>Admin</label>
                            </div>
                            <div className={style.showClassic}>
                                <CheckBox id={"isHerbalist"} defaultChecked={isHerbalist} onChange={
                                    () => setIsHerbalist(!isHerbalist)
                                }/>
                                <label htmlFor={"isHerbalist"}>Herbalist</label>
                            </div>
                            <div className={style.showClassic}>
                                <CheckBox id={"isStockManager"} defaultChecked={isStockManager} onChange={
                                    () => setIsStockmanager(!isStockManager)
                                }/>
                                <label htmlFor={"isStockManager"}>Stock Manager</label>
                            </div>
                        </div>
                    </div>
                    {( isHerbalist &&
                    <>
                    <div className={style.userDetailsContentRow}>
                        <div>{"Fulfillment fee:"}</div>
                        <div><input type={'number'} defaultValue={props.herbalist.fulfillment_fee}
                                    required={true} step={0.01} min={0}
                                    onChange={e => setFulfillmentFee(e.target.value)} /></div>
                    </div>
                    <div className={style.userDetailsContentRow}>
                        <div>{"Own prices:"}</div>
                        <div><CheckBox id={"isOwnPrices"} defaultChecked={ownPrices}
                                       onChange={()=>setOwnPrices(!ownPrices)}
                        /></div>
                    </div>
                    </>
                    )}
                    <div className={style.userDetailsContentRow}>
                        <div>{"Notes:"}</div>
                        <div><textarea onChange={ e => setNotes(e.target.value) }>{notes}</textarea></div>
                    </div>
                </div>

                <div className={style.userDetailsFooter}>
                    <SubmitButton
                        value={ props.herbalist.herbalist_id ? "Save" : "Submit" }
                    />
                    <Button onClick={props.onCancel}>Cancel</Button>
                </div>
            </div>
        </form>
    );

}

export default UserDetails