import React from "react"

import Button from '@atlaskit/button/standard-button';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle,
    ModalTransition,
} from '@atlaskit/modal-dialog';


const InfoDialog = (props) => {
    return (
        <>
            <ModalTransition>
                <Modal onClose={props.onCloseModal}>
                    <ModalHeader>
                        <ModalTitle>
                            {props.title}
                        </ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        {props.body}
                    </ModalBody>
                    <ModalFooter>
                        <Button appearance="primary" onClick={props.onCloseModal} >Ok</Button>
                    </ModalFooter>
                </Modal>
            </ModalTransition>
        </>
    );
}

export default InfoDialog