import  jwt_decode from "jwt-decode"

const endPoint = `${process.env.GATSBY_API_URL}` || '/api/v1';

const loginAcu = async (email, password, loginHandler, errorHandler) => {
    let response = await fetch(endPoint + 'auth/login',{
        method: "POST",
        headers: {"content-type": "application/json" },
        body: JSON.stringify({email: email, password: password})
    })

    console.log(response.status)
    if (response.status !== 200) {
        const errMessage = await response.text()
        console.log(errMessage)
        return errorHandler(errMessage)
    } else {
        const data = await response.json()
        console.log(data)
        let user = data.user
        if ( user && user.id ) {
            localStorage.setItem('user', JSON.stringify(user))
            localStorage.setItem('accessToken', data.accessToken)
            localStorage.setItem('refreshToken', data.refreshToken)
            return loginHandler( user );
        }
    }
}

const logoutAcu = async (refreshToken) => {
    await fetch(endPoint + 'auth/logout',{
        method: "POST",
        headers: {"content-type": "application/json" },
        body: JSON.stringify({refreshToken: refreshToken})
    })
        .finally(onFinally => {
                localStorage.removeItem('user')
                localStorage.removeItem('accessToken')
                localStorage.removeItem('refreshToken')
        })
}

const refreshAccessToken = async () => {
    return await fetch(endPoint + 'auth/refresh', {
        method: 'POST',
        headers: {"content-type": "application/json" },
        body: JSON.stringify({ refreshToken: localStorage.getItem('refreshToken') })
    })
        .then( response => {
            if ( response.status === 400 ) {
                localStorage.removeItem('accessToken')
                localStorage.removeItem('refreshToken')
            }
            return response.json()
        })
        .then( data => {
            if (data.accessToken){
                localStorage.setItem('accessToken', data.accessToken)
                localStorage.setItem('refreshToken', data.refreshToken)
                return data.accessToken
            } else {
                localStorage.removeItem('accessToken')
                localStorage.removeItem('refreshToken')
                return null
            }
        })
        .catch( err => {console.log(err) } )
}

const fetchAcu = async (resource, init = {}) => {
    resource = endPoint + resource
    var accessToken = localStorage.getItem('accessToken')
    if (accessToken) {
        let currentDate = new Date()
        const decodedToken = jwt_decode(accessToken)
        if ( decodedToken.exp * 1000 < currentDate.getTime()) {
            accessToken = await refreshAccessToken()
        }
    }
    if (accessToken) {
        init.credentials = 'same-origin'
        let headers = init.headers || {}
        headers['Authorization'] = 'Bearer ' + accessToken
        headers['Content-type'] = 'application/json';

        init.headers = headers
    }
    return fetch(resource, init)
}

async function getAcu(resource, init = {}) {
    init.method = 'GET'
    return fetchAcu(resource, init)
}

async function putAcu(resource, init = {}) {
    init.method = 'PUT'
    return fetchAcu(resource, init)
}

async function postAcu(resource, init = {}) {
    init.method = 'POST'
    return fetchAcu(resource, init)
}

async function deleteAcu(resource, init = {}) {
    init.method = 'DELETE'
    return fetchAcu(resource, init)
}

export {loginAcu, logoutAcu, fetchAcu, getAcu, postAcu, putAcu, deleteAcu}