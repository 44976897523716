import React, {useEffect, useState} from "react"
import * as style from "./applicants.module.scss"
import {caseInsensitiveSort} from "../data-table-utils";
import DataTable from "react-data-table-component";
import {fetchAcu} from "../fetchacu";
// import styled from "styled-components";
import DeleteDialog from "../deleteDialog/deleteDialog";
import ApplicantDetails from "./applicantDetails/applicantDetails";


const Applicants = (props) => {
    const [rows, setRows] = useState([])
    const [pending, setPending] = useState(true)
    const [showUserDetails, setShowUserDetails] = useState({})
    const [userToDel, setUserToDel] = useState({})

    const deleteUser = async (regUuid) => {
        let response = await fetchAcu('admin/applicant/' + regUuid, {method: 'DELETE'})
        if ( response.status === 200 ) {
            const okMessage = await response.text()
            setRows( rows.filter( row => (row.reg_uuid !== regUuid) ))
            console.log("delete application: " + okMessage)
            setShowUserDetails({})
        } else {
            const errorMessage = await response.text()
            console.log("delete application error: " + errorMessage)
            props.onError({status: response.status, message: errorMessage})
        }
    };

    useEffect(() => {
        fetchAcu('admin/applicants')
            .then(
                response => {
                    if ( response.status === 200 )
                        response.json().then( data => {setRows(data); setPending(false)})
                    else {
                        response.text().then(err => {console.log(err); props.onError({status: response.status, message: err})})
                    }
                }
            ).catch(err => {
            console.log(err);
            props.onError({status: 1, message: err});
        })
    }, [props]);

    const handleOnCreateUser = (herbalist) => {
        // setRows(rows.filter(r => r.reg_uuid !== herbalist.herbalist_id).concat(herbalist))
        setShowUserDetails({})
    }

    const columns = [
        {
            id: "reg_uuid",
            name: "ID",
            selector: row => row.reg_uuid,
            sortable: true,
            width: '290px',
        },
        {
            id: 'email',
            name: 'Email',
            selector: row => row.email,
            sortFunction: caseInsensitiveSort('email'),
        },
        {
            id: 'name',
            name: 'Name',
            selector: row => row.first_name,
            format: row => [row.first_name, row.middle_name, row.last_name].join(' '),
            sortFunction: caseInsensitiveSort('first_name'),
        },
        {
            id: 'approved',
            name: "Approved",
            selector: row => row.approved,
        },
        {
            id: 'ctime',
            name: "CTime",
            selector: row => row.ctime
        },
        {
            id: 'buttons',
            name: '',
            cell: (row) => (<>
                <button onClick={() => setShowUserDetails(row)}
                        title={'Edit ' + row.herbalist_name}
                        className={style.buttonEditUser}>&#x270D;</button>
                {( row.herbalist_id !== props.user.id &&
                    <button onClick={() => setUserToDel(row)}
                            title={'Delete ' + row.herbalist_name}
                            className={style.buttonRemoveUser}>x</button>
                )}
            </>),
            width: '130px',
        },
    ]

    return (
        <>
            {( userToDel.reg_uuid &&
                <DeleteDialog
                    key={userToDel.reg_uuid}
                    onCloseModal={() => setUserToDel({})}
                    onClickDelete={() => {deleteUser(userToDel.reg_uuid); setUserToDel({})} }
                    title={(<>{'You’re about decline to '}<em>{userToDel.email}</em></>)}
                />
            )}

            {(
                ( showUserDetails.reg_uuid )
                    ?
                    <ApplicantDetails applicant={showUserDetails}
                                 user={props.user}
                                 onCancel={()=>{setShowUserDetails({})}}
                                 onCreate={handleOnCreateUser}
                                 onDecline={() =>{setUserToDel(showUserDetails)}}
                                 onError={props.onError}
                    />
                    :
                    <div className={style.applicants}>
                        <DataTable
                            keyField={'reg_uuid'}
                            columns={columns}
                            data={rows}
                            progressPending={pending}
                            persistTableHead
                            subHeader
                            // subHeaderComponent={subHeaderComponentMemo}
                            pagination
                            paginationPerPage={10}
                            highlightOnHover={true}
                            // dense={true}
                            defaultSortFieldId={'ctime'}
                            defaultSortAsc={false}
                        />
                    </div>
            )}
        </>
    )
}

export default Applicants